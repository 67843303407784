export const TarrifsOutlinedIcon = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.963"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6608 0C11.9112 0 12.1618 0 12.4122 0C13.2403 0.851596 14.0779 1.697 14.9249 2.5362C15.0309 2.82621 15.0153 3.10801 14.878 3.38161C14.0874 4.17221 13.2968 4.96283 12.5062 5.75343C11.8469 6.07346 11.4399 5.87779 11.285 5.16634C11.3097 5.03615 11.3489 4.91094 11.4024 4.79061C11.7703 4.4227 12.1383 4.05479 12.5062 3.68689C11.3164 3.65558 10.1265 3.65558 8.93669 3.68689C5.51441 4.18043 3.17387 6.0356 1.91516 9.25245C1.12974 11.6376 1.34891 13.9233 2.5727 16.1096C2.75394 16.5648 2.63653 16.917 2.22045 17.1663C1.88867 17.2483 1.59904 17.1779 1.35156 16.955C-0.172926 14.2319 -0.40776 11.3982 0.647061 8.45401C2.10192 4.96706 4.69292 2.90837 8.42006 2.27789C9.78101 2.21934 11.143 2.18803 12.5062 2.18395C12.0881 1.7973 11.7045 1.38243 11.3555 0.939335C11.2406 0.552662 11.3424 0.239549 11.6608 0Z"
        fill="white"
      />
      <path
        opacity="0.962"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3436 6.76325C17.8138 6.68124 18.1504 6.85347 18.3534 7.27988C20.0295 10.62 19.9356 13.9076 18.0716 17.1429C16.4364 19.6955 14.1116 21.2063 11.0971 21.6752C9.73611 21.7338 8.37407 21.765 7.01095 21.7691C7.37884 22.137 7.74678 22.5049 8.11467 22.8728C8.3071 23.2519 8.25228 23.5885 7.95029 23.8826C7.65285 24.0392 7.35536 24.0392 7.05792 23.8826C6.25164 23.0763 5.44541 22.2701 4.63913 21.4638C4.48259 21.182 4.48259 20.9002 4.63913 20.6184C5.46105 19.7965 6.28297 18.9746 7.10489 18.1527C7.60457 17.933 7.9725 18.0661 8.20861 18.5519C8.2637 18.8091 8.21673 19.044 8.06771 19.2564C7.73109 19.593 7.39453 19.9296 7.05792 20.2662C8.23209 20.2975 9.40626 20.2975 10.5804 20.2662C14.1834 19.7522 16.5709 17.7718 17.7429 14.3249C18.3891 12.0354 18.123 9.85925 16.9444 7.79651C16.793 7.33488 16.9261 6.99047 17.3436 6.76325Z"
        fill="white"
      />
      <path
        opacity="0.972"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50022 7.04502C9.94518 6.95832 10.2661 7.11491 10.463 7.51469C10.4865 7.8274 10.4943 8.14052 10.4865 8.45403C11.0347 8.44618 11.5826 8.45403 12.1304 8.47751C12.6105 8.7262 12.7279 9.09409 12.4826 9.58123C12.3916 9.70362 12.2742 9.78971 12.1304 9.83955C11.1127 9.85519 10.0951 9.87087 9.07752 9.88651C8.52918 9.9897 8.31783 10.3106 8.44347 10.8493C8.58197 11.0949 8.79332 11.2279 9.07752 11.2485C9.68808 11.2642 10.2987 11.2799 10.9092 11.2955C12.1468 11.6479 12.7026 12.4698 12.5765 13.7613C12.3156 14.8661 11.6189 15.4454 10.4865 15.499C10.4943 15.8125 10.4865 16.1257 10.463 16.4384C10.2662 16.8459 9.94527 16.9946 9.50022 16.8846C9.24951 16.7903 9.10081 16.6102 9.05403 16.3444C9.0306 16.0631 9.02275 15.7813 9.03055 15.499C8.4824 15.5069 7.93444 15.499 7.38671 15.4756C6.91207 15.1941 6.81034 14.8106 7.08143 14.3249C7.16273 14.2254 7.26451 14.155 7.38671 14.1135C8.41998 14.0979 9.45325 14.0822 10.4865 14.0666C10.9695 13.975 11.1808 13.6854 11.1206 13.1977C11.0038 12.9084 10.7924 12.744 10.4865 12.7045C9.86031 12.6889 9.23406 12.6732 8.60785 12.6575C7.45923 12.3099 6.90347 11.535 6.94053 10.3327C7.15672 9.16924 7.85342 8.54303 9.03055 8.45403C9.01251 8.12108 9.036 7.79231 9.101 7.46772C9.18028 7.27117 9.31334 7.13027 9.50022 7.04502Z"
        fill="white"
      />
    </svg>
  );
};

export const HelpCircleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 4.63636C7.93318 4.63636 4.63636 7.93318 4.63636 12C4.63636 16.0668 7.93318 19.3636 12 19.3636C16.0668 19.3636 19.3636 16.0668 19.3636 12C19.3636 7.93318 16.0668 4.63636 12 4.63636ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        fill="#787878"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1941 8.77414C11.8373 8.70432 11.4705 8.78082 11.1585 8.99009C10.8465 9.19937 10.6094 9.52792 10.4893 9.91755C10.3489 10.3731 9.9114 10.6126 9.51219 10.4523C9.11298 10.292 8.9032 9.79276 9.04363 9.33717C9.28383 8.55791 9.75794 7.90081 10.382 7.48226C11.006 7.0637 11.7397 6.9107 12.4531 7.05036C13.1666 7.19001 13.8136 7.6133 14.2798 8.24527C14.7459 8.8771 15.001 9.67675 15 10.5026C14.9997 11.8409 14.1302 12.7247 13.5094 13.197C13.1756 13.451 12.8472 13.6377 12.6054 13.7604C12.4833 13.8223 12.3806 13.8693 12.3065 13.9015C12.2694 13.9176 12.2394 13.9301 12.2175 13.939L12.1909 13.9496L12.1824 13.9529L12.1794 13.9541L12.1782 13.9545C12.178 13.9546 12.1773 13.9549 11.935 13.1253L12.1773 13.9549C11.7758 14.1076 11.3419 13.86 11.208 13.4018C11.0743 12.9439 11.2909 12.4491 11.6919 12.296L11.6909 12.2964C11.691 12.2963 11.691 12.2963 11.6919 12.296L11.7041 12.2911C11.7159 12.2863 11.7352 12.2783 11.761 12.2671C11.8126 12.2446 11.8895 12.2096 11.9829 12.1622C12.1721 12.0663 12.4184 11.9251 12.6593 11.7418C13.1877 11.3398 13.4675 10.9121 13.4675 10.5018L13.4675 10.5005C13.468 10.0875 13.3405 9.68758 13.1074 9.3716C12.8743 9.05562 12.5508 8.84397 12.1941 8.77414Z"
        fill="#787878"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 16C11 15.4477 11.4455 15 11.995 15H12.005C12.5545 15 13 15.4477 13 16C13 16.5523 12.5545 17 12.005 17H11.995C11.4455 17 11 16.5523 11 16Z"
        fill="#787878"
      />
    </svg>
  );
};
