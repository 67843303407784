import { Button, Menu, MenuProps, Modal, Spin } from "antd";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";

import { FirebaseContext } from "../../../../App";
import { HistoryItem } from "./HistoryItem";
import { InvintationItem } from "./InvintationItem";
import { SendedItem } from "./SendedItem";
import TextArea from "antd/es/input/TextArea";
import { Title } from "../../../../assets/components/display/Title";
import classes from "../../styles/subtitleDashboard.module.scss";
import { locals } from "../../../../servises/locals";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { selectSubscriptionSlice } from "../../../../servises/store/slice/subscriptionSlice";
import { useSelector } from "react-redux";
import { getFunctions, httpsCallable } from "firebase/functions";

const generateEmailsListFromString = (value: string): string[] => {
  const emailRegex = /([+a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
  const emailArray = value.match(emailRegex);
  if (emailArray) {
    return emailArray;
  }
  return [];
};

enum TargetEnum {
  INVITATIONS = "invitations",
  SENDED_REQUESTS = "requests",
  HISTORY = "history",
}

export const Invitations = () => {
  const functions = getFunctions();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(TargetEnum.HISTORY);
  const [textAreaValue, setTextAreaValue] = useState("");
  const companyRefId = localStorage.getItem("company_ref_id") || "";
  const userRefId = localStorage.getItem("user_uid") || "";
  const [companyInvitationCollection, setCompanyInvitationCollection] =
    useState<any[]>();
  const [companyRequestsCollection, setCompanyRequestsCollection] =
    useState<any[]>();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const db = useContext(FirebaseContext);
  const { subscription } = useSelector(selectSubscriptionSlice);

  const switchTarget = (targetValue: TargetEnum) => {
    switch (targetValue) {
      case TargetEnum.INVITATIONS:
        return (
          companyInvitationCollection &&
          companyInvitationCollection
            .filter((item) => item.invitation.state === "PENDING")
            .map((user: any, userIndex: any) => {
              return (
                <InvintationItem
                  addUser={addUser}
                  invitation={user.invitation}
                  rejectUser={rejectUser}
                  key={user.invitation.id}
                  index={userIndex + 1}
                  blockUser={blockUser}
                />
              );
            })
        );
      case TargetEnum.HISTORY:
        return (
          companyInvitationCollection &&
          companyInvitationCollection
            .filter(
              (item) =>
                item.invitation.state === "ACCEPTED" ||
                item.invitation.state === "REJECTED" ||
                item.invitation.state === "BLOCKED"
            )
            .map((user: any, userIndex: any) => {
              return (
                <HistoryItem
                  invitation={user.invitation}
                  key={user.invitation.id}
                  index={userIndex + 1}
                />
              );
            })
        );
      case TargetEnum.SENDED_REQUESTS:
        return (
          companyRequestsCollection &&
          companyRequestsCollection.map((user: any, userIndex: any) => {
            return (
              <SendedItem
                invitation={user.invitation}
                key={user.invitation.id}
                index={userIndex + 1}
              />
            );
          })
        );

      default:
        break;
    }
  };

  const refreshInvintations = async () => {
    const companies = query(
      collection(db, "company_join_requests"),
      where("company_ref", "==", doc(db, "companies", companyRefId))
    );
    let value: any = [];

    const docs = await getDocs(companies);
    if (docs.docs.length === 0) {
      setCompanyInvitationCollection([]);
    }
    await docs.forEach(async (item) => {
      value = [
        ...value,
        ...[
          {
            invitation: { ...item.data(), ...{ id: item.id } },
          },
        ],
      ];
      setCompanyInvitationCollection(value);
    });
  };

  const refreshRequests = async () => {
    const companies = query(
      collection(db, "user_invitations"),
      where("company_ref", "==", doc(db, "companies", companyRefId))
    );
    let value: any = [];

    const docs = await getDocs(companies);
    if (docs.docs.length === 0) {
      setCompanyRequestsCollection([]);
    }

    await docs.forEach(async (item) => {
      value = [
        ...value,
        ...[
          {
            invitation: { ...item.data(), ...{ id: item.id } },
          },
        ],
      ];

      setCompanyRequestsCollection(value);
    });
  };
  useEffect(() => {
    refreshInvintations();
    refreshRequests();
  }, []);

  const inviteUsersToCompany = async (values: string[]) => {
    values.forEach(async (item) => {
      if (localStorage.getItem("company_ref_id")) {
        setMessage("");
        setLoading(true);

        const addMessage = httpsCallable(
          functions,
          "addUserInvitation2Company"
        );
        const res = await addMessage({
          company_id: companyRefId,
          email: item,
        });

        const { data }: { data: any } = res;
        if (data.status !== 200) {
          setMessage(data.message);
          setLoading(false);
          return;
        }

        setLoading(false);
        setTextAreaValue("");
        refreshRequests();
      }
    });
  };

  const handleInviteButton = (event: any) => {
    const emails = generateEmailsListFromString(textAreaValue);

    inviteUsersToCompany(emails);
  };

  const addUser = (userId: string) => {
    updateDoc(doc(db, "company_join_requests", userId), {
      state: "ACCEPTED",
    });
    refreshInvintations();
  };

  const rejectUser = (userId: string) => {
    updateDoc(doc(db, "company_join_requests", userId), {
      state: "REJECTED",
    });
    refreshInvintations();
  };

  const blockUser = (userId: string) => {
    updateDoc(doc(db, "company_join_requests", userId), {
      state: "BLOCKED",
    });
    refreshInvintations();
  };

  const items: MenuProps["items"] = [
    {
      label: locals.title.history,
      key: TargetEnum.HISTORY,
    },
    {
      label: locals.title.inbox,
      key: TargetEnum.INVITATIONS,
    },
    {
      label: locals.title.sended_requests,
      key: TargetEnum.SENDED_REQUESTS,
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    setTarget(e.key as TargetEnum);
  };

  return (
    <>
      {subscription ? (
        <Button
          className={classes.inventetion_control}
          type="primary"
          onClick={() => setOpen(true)}
          style={{ borderRadius: "50px" }}
        >
          <PlusOutlined /> ADD
          {!!switchTarget(TargetEnum.INVITATIONS)?.length && (
            <span className={classes.inventetion_control__count}>{switchTarget(TargetEnum.INVITATIONS)?.length}</span>
          )}
        </Button>
      ) : (
        <Button
          className={classes.inventetion_control__disabled}
          type="primary"
          style={{ borderRadius: "50px" }}
          onClick={() => navigate("/payboard")}
        >
          <PlusOutlined /> ADD
        </Button>
      )}
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
          setOpen(false);
        }}
        width={"calc(80vw - 500px)"}
        style={{ minWidth: "800px" }}
      >
        <Title>{locals.title.invitations}</Title>
        <div className={classes.invintetion_modal__content}>
          <div className={classes.invintetion_modal__input_container}>
            <TextArea
              style={{ height: "100%", resize: "none" }}
              onChange={(event) => {
                setTextAreaValue(event.target.value);
              }}
              placeholder="Enter email"
              value={textAreaValue}
            />
            <p>{message}</p>
            <Button
              disabled={loading}
              type="primary"
              className={classes.invintetion_modal__input_button}
              onClick={handleInviteButton}
            >
              {loading ? <Spin /> : "Send invitation"}
            </Button>
          </div>

          <div className={classes.invintetion_modal__user_list}>
            <Menu
              mode="horizontal"
              selectedKeys={[target]}
              items={items}
              onClick={onClick}
            ></Menu>
            {switchTarget(target)}
          </div>
        </div>
      </Modal>
    </>
  );
};
