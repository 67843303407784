import {
  PayConditionSection,
  PayConditionStatusEnum,
} from "./PayConditionSection";

import { PayConditionPrice } from "./PayConditionPrice";
import classes from "../styles/payCondition.module.scss";
import { locals } from "../../../servises/locals";

export interface IPayConditionProps {
  billing_period_duration: number;
  billing_period_unit: string;
  currency: string;
  flat_fee_amount: number;
  flat_fee_stripe_price_id: string;
  month: {
    billing_period_duration: number;
    billing_period_unit: string;
    currency: string;
    flat_fee_amount: number;
    flat_fee_stripe_price_id: string;
    per_user_fee_amount: number;
    per_user_fee_stripe_price_id: string;
    plan: string;
    id: string;
    onClick: () => void;
  };
  per_user_fee_amount: number;
  per_user_fee_stripe_price_id: string;
  plan: string;
  active: boolean;
  onClick: () => void;
  loading: boolean;
  id: string;
  features: Array<{ feature: string }>;
}

export const PayCondition = ({
  billing_period_duration,
  billing_period_unit,
  currency,
  flat_fee_amount,
  flat_fee_stripe_price_id,
  month,
  per_user_fee_amount,
  per_user_fee_stripe_price_id,
  plan,
  active,
  onClick,
  features,
  id,
  loading,
}: IPayConditionProps) => {
  const isFeatureActive = (feature: string): boolean => {
    return (
      features.filter((itemFeature) => itemFeature.feature === feature)
        .length !== 0
    );
  };
  return (
    <div
      className={
        active
          ? classes.pay_condition__container_active
          : classes.pay_condition__container
      }
    >
      <p className={classes.pay_condition__title}>{plan}</p>
      <PayConditionSection
        status={PayConditionStatusEnum.ACTIVE}
        title={locals.pay_board.condition.user_fee}
      />
      <PayConditionSection
        status={
          isFeatureActive("ANALYTIC_DASHBOARD")
            ? PayConditionStatusEnum.ACTIVE
            : PayConditionStatusEnum.UNABLE
        }
        title={locals.pay_board.condition.analytic_dashboard}
      />
      <PayConditionSection
        status={
          isFeatureActive("EXCEL_EXPORT")
            ? PayConditionStatusEnum.ACTIVE
            : PayConditionStatusEnum.UNABLE
        }
        title={locals.pay_board.condition.all_data_in_excel}
      />
      <PayConditionSection
        status={
          isFeatureActive("CUSTOMIZATION")
            ? PayConditionStatusEnum.ACTIVE
            : PayConditionStatusEnum.UNABLE
        }
        title={locals.pay_board.condition.customization}
      />
      <PayConditionSection
        status={
          isFeatureActive("INDIVIDUAL_TECH_SUPPORT")
            ? PayConditionStatusEnum.ACTIVE
            : PayConditionStatusEnum.UNABLE
        }
        title={locals.pay_board.condition.individual_tech}
      />
      <PayConditionSection
        status={
          isFeatureActive("PERSONAL_SERVER")
            ? PayConditionStatusEnum.ACTIVE
            : PayConditionStatusEnum.UNABLE
        }
        title={locals.pay_board.condition.personal_server}
      />
      <PayConditionSection
        status={
          true ? PayConditionStatusEnum.ACTIVE : PayConditionStatusEnum.UNABLE
        }
        title={locals.pay_board.condition.premium_account}
      />

      <div className={classes.pay_condition_section__footer}>
        <PayConditionPrice
          loading={loading}
          isActive={active}
          price={`${month.flat_fee_amount / 100}.00 $`}
          subTitle={locals.pay_board.condition.per_month}
          id={month.id}
          onClick={month.onClick}
        />
        <PayConditionPrice
          loading={loading}
          isActive={!active}
          price={`${flat_fee_amount / 100}.00 $`}
          subTitle={locals.pay_board.condition.per_year}
          id={id}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
