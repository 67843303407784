import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  where,
} from "@firebase/firestore";
import { useContext, useState, useEffect } from "react";
import { FilterContext, FilterUserEnum } from "../routes/dashboard";
import { FirebaseContext } from "../App";

export const useGetUsers = () => {
  const db = useContext(FirebaseContext);
  const companyRefId = localStorage.getItem("company_ref_id") || "";
  const [usersCollection, setUsersCollection] = useState<any[]>([]);

  const getUsers = async () => {
    const companies = query(
      collection(db, "company_users"),
      where("company_ref", "==", doc(db, "companies", companyRefId))
    );
    let value: any = [];
    const docs = await getDocs(companies);

    docs.forEach(async (item) => {
      const docUserSnap = await getDoc(item.data().user_ref);

      value = [...value, ...[{ user: docUserSnap.data() }]];

      setUsersCollection(value);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return { usersCollection, getUsers };
};
