export enum StatusEnum {
  ACCEPTED = "ACCEPTED",
  CREATED = "CREATED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  BLOCKED = "BLOCKED",
}

export const Status = ({ status }: { status: StatusEnum }) => {
  const switchColor = () => {
    switch (status) {
      case StatusEnum.ACCEPTED:
        return "var(--accepted)";
      case StatusEnum.CREATED:
        return "var(--green)";
      case StatusEnum.PENDING:
        return "var(--pending)";
      case StatusEnum.REJECTED:
        return "var(--rejected)";
      case StatusEnum.BLOCKED:
        return "var(--black)";

      default:
        break;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "30px",
        width: "80px",
        borderRadius: "50px",
        border: "1px solid " + switchColor(),
        backgroundColor: switchColor(),
        color: "white",
      }}
    >
      {status}
    </div>
  );
};
