import { assoc, collectBy, prop } from "ramda";
import { collection, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";

import { BaseLayout } from "../../assets/components/display/Layout";
import { FirebaseContext } from "../../App";
import { Header } from "../../assets/components/display/Header";
import { PayCondition } from "./components/PayCondition";
import classes from "./styles/payboard.module.scss";
import { locals } from "../../servises/locals";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../servises/store";
import { ContactForm } from "../help/components/contactForm";
import { Spin } from "antd";
import { getCompaniesThunk, getSubcriptionInfoThunk } from "../../servises/store/slice/subscriptionSlice";

export const PayBoard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { subscription, loaded } = useSelector(
    (state: RootState) => state.subscription
  );
  const functions = getFunctions();
  const [loading, setLoading] = useState(false);
  const db = useContext(FirebaseContext);
  const [activeConditionState, setActiveConditionState] = useState([
    {
      active: false,
      billing_period_duration: 0,
      billing_period_unit: "YEAR",
      currency: "USD",
      flat_fee_amount: 0,
      flat_fee_stripe_price_id: "",
      features: [],
      month: {
        billing_period_duration: 0,
        billing_period_unit: "YEAR",
        currency: "USD",
        flat_fee_amount: 0,
        flat_fee_stripe_price_id: "",
        per_user_fee_amount: 0,
        per_user_fee_stripe_price_id: "",
        plan: "",
        id: "",
      },
      per_user_fee_amount: 0,
      per_user_fee_stripe_price_id: "",
      plan: "",

      title: "",
      id: "",
    },
  ]);
  useEffect(() => {
    dispatch(getCompaniesThunk());
    dispatch(getSubcriptionInfoThunk());
  }, []);

  useEffect(() => {
    const companies = collection(db, "company_pricing_plans");
    getDocs(companies).then((result) => {
      let payList: any[] = [];
      result.forEach((item) => {
        payList = [...payList, ...[{ ...item.data(), ...{ id: item.id } }]];
      });

      payList = collectBy(prop("plan"), payList);
      payList = payList.map((plan) => {
        if (plan[0].billing_period_unit === "YEAR") {
          return assoc("month", plan[1], plan[0]);
        } else {
          return assoc("month", plan[0], plan[1]);
        }
      });

      payList = payList.map((itemPay) => {
        return { ...activeConditionState[0], ...itemPay };
      });

      setActiveConditionState(payList);
    });
  }, []);

  const handleClick = (id: string, payId: string) => {
    setLoading(true);
    setActiveConditionState(
      activeConditionState.map((item) => {
        if (item.id === id) {
          item.active = true;
          return item;
        }
        item.active = false;
        return item;
      })
    );

    const addMessage = httpsCallable(functions, "createStripeCheckoutSession");
    addMessage({
      company_pricing_plan_uid: payId,
      admin_user_uid: localStorage.getItem("user_uid"),
    })
      .then((result) => {
        const data: any = result.data;
        if (data.paymentUrl) {
          setLoading(false);
          window.location.href = data.paymentUrl;
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <BaseLayout>
      <Header title={!subscription ? locals.title.choose_tarif_plan : ""} />
      {loaded ? (
        !subscription ? (
          <div className={classes.payboard_container}>
            {activeConditionState.map((item) => (
              <PayCondition
                {...item}
                loading={loading}
                month={{
                  ...item.month,
                  ...{
                    onClick: () => {
                      handleClick(item.id, item.month.id);
                    },
                  },
                }}
                onClick={() => {
                  handleClick(item.id, item.id);
                }}
              />
            ))}
          </div>
        ) : (
          <div className={classes.formContainer}>
            <ContactForm title="Please reach us using contact form if you want to upgrade or cancel your tariff" />
          </div>
        )
      ) : (
        <div className={classes.loaderContainer}>
          <Spin />
        </div>
      )}
    </BaseLayout>
  );
};
