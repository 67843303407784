import { Button } from "antd";
import classes from "../styles/payCondition.module.scss";

interface IPayConditionPriceProps {
  isActive: boolean;
  price: string;
  subTitle: string;
  onClick: () => void;
  id: string;
  loading: boolean;
}

export const PayConditionPrice = ({
  isActive,
  price,
  subTitle,
  onClick,
  loading,
}: IPayConditionPriceProps) => {
  return (
    <Button
      disabled={loading}
      onClick={onClick}
      className={
        isActive
          ? classes.pay_condtion_price__container_active
          : classes.pay_condtion_price__container
      }
    >
      <span className={classes.pay_condition_price__title}>{price}</span>
      <span
        className={
          isActive
            ? classes.pay_condition_price__subtitle_active
            : classes.pay_condition_price__subtitle
        }
      >
        {subTitle}
      </span>
    </Button>
  );
};
