import { Button, Select, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { Chart } from "../../../../assets/components/data/Chart";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../servises/store";
import {
  getUsersAnalyticsThunk,
  setAnalyticsData,
  setBodyAnalyticsData,
} from "../../../../servises/store/slice/userAnalyticsSlice";
import { FilterContext } from "../..";
import classes from "../../styles/dashboard.module.scss";
import { applyMeasureSystem, getMeasureSystem } from "../../../../utils";

export const UserMainChart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { filter } = useContext(FilterContext);
  const USER_CHART_TABS = ["Weight", "Alcohol", "Smoking"];
  const BODY_CATEGORIES = [
    { label: "Body", value: null, disabled: true },
    {
      label: "Hips",
      value: "hip",
    },
    {
      label: "Chest",
      value: "breast",
    },
    {
      label: "Waist",
      value: "waist",
    },
  ];
  const [selectedBody, setSelectedBody] = useState(BODY_CATEGORIES[0].value);

  const [activeButton, setActiveButton] = useState("Weight");
  const analyticsData = useSelector(
    (state: RootState) => state.usersAnalytics.analyticsData
  );
  const loading = useSelector(
    (state: RootState) => state.usersAnalytics.loading
  );

  const changeChartHandler = (tab: string) => {
    setTab(tab);
  };

  const changeBodyChartHandler = (value: string) => {
    setSelectedBody(value);
    dispatch(setBodyAnalyticsData(value));
    setActiveButton(value);
  };

  const renderMeasurementLabel = () => {
    if (activeButton === USER_CHART_TABS[0]) {
      return getMeasureSystem() === "US" ? "lb" : "kg";
    } else if (
      BODY_CATEGORIES.map((item) => item.value).includes(activeButton)
    ) {
      return getMeasureSystem() === "US" ? "inch" : "cm";
    }
  };

  const setTab = (tab: string) => {
    setSelectedBody(BODY_CATEGORIES[0].value);
    dispatch(setAnalyticsData(tab));
    setActiveButton(tab);
  };

  useEffect(() => {
    setTab(USER_CHART_TABS[0]);
    dispatch(getUsersAnalyticsThunk({ filter, tab: USER_CHART_TABS[0] }));
  }, [filter.currentUser, filter.period]);

  return (
    <>
      <div className={classes.userMainChartTabs}>
        <div className={classes.item}>
          <Button
            onClick={() => changeChartHandler(USER_CHART_TABS[0])}
            className={
              activeButton === USER_CHART_TABS[0]
                ? `${classes.active}`
                : `${classes.button}`
            }
          >
            {USER_CHART_TABS[0]}
          </Button>
        </div>
        <div className={classes.selector}>
          <Select
            bordered={false}
            size="middle"
            className={
              BODY_CATEGORIES.map((item) => item.value).includes(activeButton)
                ? `${classes.active} ${classes.bodyTab}`
                : `${classes.bodyTab}`
            }
            value={selectedBody}
            onChange={(value) => changeBodyChartHandler(value)}
            options={BODY_CATEGORIES}
          />
        </div>
        {USER_CHART_TABS.slice(1).map((tab) => (
          <div className={classes.item} key={tab}>
            <Button
              onClick={() => changeChartHandler(tab)}
              className={
                activeButton === tab ? `${classes.active}` : `${classes.button}`
              }
            >
              {tab}
            </Button>
          </div>
        ))}
      </div>
      {loading ? (
        <div className={classes.loader}>
          <Spin />
        </div>
      ) : (
        <div className={classes.chart}>
          <Chart
            height={350}
            data={applyMeasureSystem(
              analyticsData,
              activeButton === USER_CHART_TABS[0] ? "weight" : "distance",
              activeButton === USER_CHART_TABS[0] ||
                BODY_CATEGORIES.map((item) => item.value).includes(activeButton)
            )}
            measurement={renderMeasurementLabel()}
          />
        </div>
      )}
    </>
  );
};
