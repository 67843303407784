import { useFormik } from "formik";
import { object, string } from "yup";
import { BaseInput } from "../../../../assets/components/input/BaseInput";
import classes from "./styles.module.scss";
import TextArea from "antd/es/input/TextArea";
import { Button, Spin } from "antd/es/";
import { addDoc, collection, doc, getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { useSelector } from "react-redux";
import { selectSubscriptionSlice } from "../../../../servises/store/slice/subscriptionSlice";
import { useState } from "react";

export const ContactForm = ({ title }: { title?: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const getDb = () => {
    const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG;
    const app = initializeApp(JSON.parse(firebaseConfig as string));
    return getFirestore(app);
  };

  const { company } = useSelector(selectSubscriptionSlice);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      company: company ? company.name : "",
      country: "",
      message: "",
    },
    validationSchema: object({
      first_name: string().required(),
      last_name: string(),
      phone_number: string(),
      email: string().email().required(),
      company: string(),
      country: string(),
      message: string().required(),
    }),
    enableReinitialize: true,

    onSubmit: async (data) => {
      setIsLoading(true);
      const db = getDb();
      const companyRef = localStorage.getItem("company_ref_id") || "";
      const dataToDB = {
        ...data,
        company_ref: doc(db, "companies", companyRef),
      };
      try {
        const res = await addDoc(collection(db, "contact_forms"), dataToDB);
        setIsLoading(false);
        setLoaded(true);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    },
  });

  const resetFormHandler = () => {
    formik.resetForm();
    setLoaded(false);
  };
  return (
    <div className={classes.contact}>
      <h3>{title || "If you have additional questions"}</h3>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <h4>Contact us</h4>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <BaseInput
              name="first_name"
              disabled={false}
              label="First name"
              type="text"
              valid={!(formik.errors.first_name && formik.touched.first_name)}
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={classes.input}>
            <BaseInput
              name="last_name"
              disabled={false}
              label="Last name"
              type="text"
              valid={!(formik.errors.last_name && formik.touched.last_name)}
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={classes.input}>
            <BaseInput
              name="phone_number"
              disabled={false}
              label="Phone number"
              type="text"
              valid={
                !(formik.errors.phone_number && formik.touched.phone_number)
              }
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={classes.input}>
            <BaseInput
              name="email"
              disabled={false}
              label="E-mail"
              type="text"
              valid={!(formik.errors.email && formik.touched.email)}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={classes.input}>
            <BaseInput
              name="company"
              disabled={false}
              label="Company"
              type="text"
              valid={!(formik.errors.company && formik.touched.company)}
              value={formik.values.company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={classes.input}>
            <BaseInput
              name="country"
              disabled={false}
              label="Country"
              type="text"
              valid={!(formik.errors.country && formik.touched.country)}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className={`${classes.input} ${classes.textarea}`}>
          <TextArea
            id="message"
            name="message"
            placeholder="Your message"
            onBlur={formik.handleBlur}
            status={
              !(formik.errors.message && formik.touched.message) ? "" : "error"
            }
            value={formik.values.message}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classes.submit}>
          {loaded ? (
            <div className={classes.loaded}>
              <h4>Form has been sent</h4>
              <Button onClick={resetFormHandler}>OK</Button>
            </div>
          ) : (
            <Button htmlType="submit" disabled={isLoading}>
              {isLoading ? <Spin /> : "Submit"}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
