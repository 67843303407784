import { Button, Spin } from "antd";
import classes from "./styles.module.scss";
import { BaseInput } from "../../../assets/components/input/BaseInput";
import {
  getAuth,
  signInWithEmailAndPassword,
  updateEmail,
} from "firebase/auth";
import { useFormik } from "formik";
import { useState } from "react";
import { object, string } from "yup";
import { errorHandler } from "../../../utils";
import { AuthErrorEnum } from "../../../type/auth";

export const UpdateEmailModal = ({ onSubmit }: { onSubmit: () => void }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const auth = getAuth();

  const formik = useFormik({
    initialValues: {
      password: "",
      new_email: "",
    },
    validationSchema: object({
      new_email: string().email().required(),
      password: string().required(),
    }),

    onSubmit: async ({ password, new_email }) => {
      setMessage("");
      setLoading(true);
      try {
        const authRes = await signInWithEmailAndPassword(
          auth,
          auth.currentUser?.email || "",
          password
        );
        if (auth.currentUser) {
          const res = await updateEmail(auth.currentUser, new_email);
          setLoading(false);
          onSubmit();
          formik.resetForm();
        }
      } catch (error: any) {
        setMessage(errorHandler(error.code as AuthErrorEnum));
        setLoading(false);
      }
    },
  });

  return (
    <div className={classes.modalContent}>
      <h1>Change E-mail</h1>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.input}>
          <BaseInput
            type="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            valid={!(formik.errors.password && formik.touched.password)}
          />
        </div>
        <div className={classes.input}>
          <BaseInput
            type="new_email"
            name="new_email"
            label="E-mail"
            value={formik.values.new_email}
            onChange={formik.handleChange}
            valid={!(formik.errors.new_email && formik.touched.new_email)}
          />
        </div>
        <p>{message}</p>
        <Button disabled={loading} type="primary" htmlType="submit">
          {loading ? <Spin /> : "Change"}
        </Button>
      </form>
    </div>
  );
};
