import { Button, Input, Modal } from "antd";
import { FilterContext, FilterUserEnum } from "..";
import { useContext, useEffect, useState } from "react";

import { Invitations } from "./invitation/Invitation";
import { SubtitleDatePicker } from "./datePicker/DatePicker";
import classes from "../styles/subtitleDashboard.module.scss";
import { locals } from "../../../servises/locals";
import { ExportToExcel } from "../../../assets/components/excel";

export const SubtitleDashboard = () => {
  const { filter, setFilter } = useContext(FilterContext);

  const [ageValues, setAgeValues] = useState({ from: "18", to: "99" });

  const [openUserFilter, setUserFilter] = useState(false);
  const [openAgeFilter, setAgeFilter] = useState(false);

  const handleUserFilter = (filterValue: string) => {
    setFilter({ ...filter, ...{ user: filterValue } });
    setUserFilter(false);
  };

  const handleAgeFilter = (ages: [number, number]) => {
    setFilter({ ...filter, ...{ age: ages } });
    setAgeFilter(false);
  };

  const switcUserFilterName = (key: FilterUserEnum) => {
    switch (key) {
      case FilterUserEnum.ALL:
        return locals.filter.user.all;
      case FilterUserEnum.USER:
        return locals.filter.user.user;
      case FilterUserEnum.MALE:
        return locals.filter.user.male;
      case FilterUserEnum.FEMALE:
        return locals.filter.user.female;

      default:
        return "";
    }
  };

  return (
    <div className={classes.subtitle__container}>
      <div className={classes.subtitle__filters}>
        <Button
          onClick={() => {
            setUserFilter(true);
          }}
          style={{ borderRadius: "50px" }}
        >
          {switcUserFilterName(filter.user)}
        </Button>
        <Button
          onClick={() => {
            setAgeFilter(true);
          }}
          style={{ borderRadius: "50px" }}
        >
          {locals.filter.age} {`(${filter.age[0]} - ${filter.age[1]})`}
        </Button>
        <SubtitleDatePicker />
        <div className={classes.subtitle__export}>
          <ExportToExcel />
        </div>
      </div>
      <div className={classes.invitation}>
        <Invitations />
      </div>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={openUserFilter}
        onCancel={() => {
          setUserFilter(false);
        }}
        onOk={() => {
          setUserFilter(false);
        }}
        width={"250px"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "200px",
            paddingTop: "20px",
          }}
        >
          {" "}
          <Button
            onClick={() => {
              handleUserFilter(FilterUserEnum.ALL);
            }}
            style={{ borderRadius: "50px", marginTop: "5px" }}
            type={filter.user === FilterUserEnum.ALL ? "primary" : "default"}
          >
            {locals.filter.user.all}
          </Button>{" "}
          <Button
            onClick={() => {
              handleUserFilter("male");
            }}
            style={{ borderRadius: "50px", marginTop: "5px" }}
            type={filter.user === FilterUserEnum.MALE ? "primary" : "default"}
          >
            {locals.filter.user.male}
          </Button>{" "}
          <Button
            onClick={() => {
              handleUserFilter("female");
            }}
            style={{ borderRadius: "50px", marginTop: "5px" }}
            type={filter.user === FilterUserEnum.FEMALE ? "primary" : "default"}
          >
            {locals.filter.user.female}
          </Button>{" "}
        </div>
      </Modal>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={openAgeFilter}
        onCancel={() => {
          setAgeValues({ from: `${filter.age[0]}`, to: `${filter.age[1]}` });
          setAgeFilter(false);
        }}
        onOk={() => {
          setAgeFilter(false);
        }}
        width={"550px"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
          }}
        >
          {" "}
          <Input
            // label="From 18"
            style={{ width: "100%", marginTop: "5px" }}
            name="From 18"
            type="number"
            value={ageValues.from}
            onChange={(event) => {
              setAgeValues({ ...ageValues, ...{ from: event.target.value } });
            }}
          />
          <Input
            // label="From 18"
            style={{ width: "100%", marginTop: "5px" }}
            name="To 99"
            type="number"
            value={ageValues.to}
            onChange={(event) => {
              if (+event.target.value < 99) {
                setAgeValues({ ...ageValues, ...{ to: event.target.value } });
              }
            }}
          />
          <Button
            onClick={() => {
              handleAgeFilter([+ageValues.from, +ageValues.to]);
            }}
            type="primary"
            style={{ marginTop: "5px" }}
          >
            DONE
          </Button>{" "}
        </div>
      </Modal>
    </div>
  );
};
