import { ReactNode } from "react";
import classes from "./styles/card.module.scss";

interface ICardProps {
  children: ReactNode;
  fullHeight?: boolean;
}

export const Card = ({ children, fullHeight = false }: ICardProps) => {
  return (
    <div
      className={
        fullHeight
          ? `${classes.common__card_body} ${classes.fullHeight}`
          : `${classes.common__card_body}`
      }
    >
      {children}
    </div>
  );
};
