import { Status } from "./Status";
import classes from "../../styles/subtitleDashboard.module.scss";

interface IHistoryItem {
  invitation: any;
  index: number;
}

export const SendedItem = ({ invitation, index }: IHistoryItem) => {
  return (
    <div className={classes.invintetion_modal__user}>
      <div className={classes.invintetion_modal__user_number}>{index}</div>
      <p className={classes.invintetion_modal__user_email}>{invitation.user_email}</p>

      <Status status={invitation.state} />
    </div>
  );
};
