import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { useContext, useEffect, useState } from "react";

import { Card } from "../../../../assets/components/display/Card";
import { FilterContext } from "../..";
import { FirebaseContext } from "../../../../App";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import classes from "../../styles/dashboardMain.module.scss";
import common_classes from "../../styles/dashboard.module.scss";
import { locals } from "../../../../servises/locals";

export const HealthScore = () => {
  const db = useContext(FirebaseContext);
  const { filter, setFilter } = useContext(FilterContext);
  const companyRefId = localStorage.getItem("company_ref_id") || "";
  const [usersCollection, setUsersCollection] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchedValues, setSearchedValues] = useState<any[]>([]);
  useEffect(() => {
    const companies = query(
      collection(db, "company_users"),
      where("company_ref", "==", doc(db, "companies", companyRefId))
    );
    let value: any = [];
    const func = async () => {
      const docs = await getDocs(companies);

      await docs.forEach(async (item) => {
        const docUserSnap = await getDoc(item.data().user_ref);

        value = [...value, ...[{ user: docUserSnap.data() }]];

        value.sort(
          (a: any, b: any) => b.user.todayScore.index - a.user.todayScore.index
        );
        setUsersCollection(value);
      });
    };

    func();
  }, []);

  useEffect(() => {
    setSearchedValues(
      usersCollection.filter(({ user }) => user.email.includes(search))
    );
  }, [search, usersCollection]);

  const handleUser = (user_uid: string) => {
    setFilter({ ...filter, ...{ user: "user", currentUser: user_uid } });
  };
  return (
    <Card>
      <div className={common_classes.card}>
        <p className={classes.dashboard_main__title}>
          {locals.title.top_health_score}
        </p>
        <Input
          width={"80%"}
          placeholder="Email search"
          suffix={<SearchOutlined />}
          value={search}
          onChange={(event: any) => {
            setSearch(event.target.value);
          }}
        />
        <div className={classes.dashboard_main__user_list}>
          {searchedValues.map((user, userIndex) => (
            <div
              onClick={() => {
                handleUser(user.user.uid);
              }}
              className={classes.dashboard_main__user}
              key={userIndex}
            >
              <div className={classes.dashboard_main__user_number}>
                {userIndex + 1}
              </div>
              <p className={classes.dashboard_main__user_name}>
                {user.user.name}
              </p>
              <p className={classes.dashboard_main__user_email}>
                {user.user.email}
              </p>
              <p className={classes.dashboard_main__user_count}>
                {(user && user.user.todayScore && user.user.todayScore.index) ||
                  "N/A"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
