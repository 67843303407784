import { PayStatus } from "./PayStatus";
import classes from "./styles/header.module.scss";
import { useSelector } from "react-redux";
import { selectSubscriptionSlice } from "../../../servises/store/slice/subscriptionSlice";
import { User } from "../../../routes/dashboard/components/User";

interface IHeaderProps {
  title: string;
}

export const Header = ({ title }: IHeaderProps) => {
  const { company } = useSelector(selectSubscriptionSlice);
  return (
    <div className={classes.header_body}>
      <span className={classes.header_title}>
        {title}
        <User />
      </span>
      <span className={classes.header_children}>
        <PayStatus />
        <div className={classes.header_children__title}>
          <h3>{company && company.name}</h3>
          <p>
            {company &&
              company.nickname.substring(
                company.nickname.length - 4,
                company.nickname.length
              )}
          </p>
        </div>
      </span>
    </div>
  );
};
