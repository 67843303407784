import { useDispatch } from "react-redux";
import { Header } from "../../assets/components/display/Header";
import { BaseLayout } from "../../assets/components/display/Layout";
import { HelpContent } from "./components/helpContent";
import { AppDispatch } from "../../servises/store";
import { useEffect } from "react";
import {
  getCompaniesThunk,
  getSubcriptionInfoThunk,
} from "../../servises/store/slice/subscriptionSlice";

export const Help = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getCompaniesThunk());
    dispatch(getSubcriptionInfoThunk());
  }, []);
  return (
    <BaseLayout>
      <Header title="FAQS" />
      <HelpContent />
    </BaseLayout>
  );
};
