import { useContext } from "react";
import { FilterContext, FilterUserEnum } from "../..";
import { Card } from "../../../../assets/components/display/Card";
import { locals } from "../../../../servises/locals";
import classes from "../../styles/dashboard.module.scss";
import { useGetUsers } from "../../../../hooks/useGetUsers";
import { useSelector } from "react-redux";
import { selectCompanyAnalytics } from "../../../../servises/store/slice/companyAnalyticsSlice";
import { Button, Tooltip } from "antd";
import { HelpCircleIcon } from "../../../../assets/images/auth/svg";

export const CompayInformation = () => {
  const users = useGetUsers().usersCollection;
  const { healthscores } = useSelector(selectCompanyAnalytics);
  const { filter } = useContext(FilterContext);
  const filterByGender = (gender: FilterUserEnum) => {
    return users.filter((item) => item.user.gender === gender);
  };

  return (
    <Card>
      <div className={classes.card}>
        {filter.user === FilterUserEnum.ALL && (
          <>
            <div className={classes.card__title}>{users.length}</div>
            <div className={classes.card__subtitle}>
              {locals.information.total_users}
            </div>
          </>
        )}
        {(filter.user === FilterUserEnum.MALE ||
          filter.user === FilterUserEnum.ALL) && (
          <>
            <div className={classes.card__title}>
              {filterByGender(FilterUserEnum.MALE).length}
            </div>
            <div className={classes.card__subtitle}>
              {locals.information.men}
            </div>
          </>
        )}
        {(filter.user === FilterUserEnum.FEMALE ||
          filter.user === FilterUserEnum.ALL) && (
          <>
            <div className={classes.card__title}>
              {filterByGender(FilterUserEnum.FEMALE).length}
            </div>
            <div className={classes.card__subtitle}>
              {locals.information.women}
            </div>
          </>
        )}
        <div className={classes.card__title}>
          {!healthscores.avg_health_score
            ? "N/A"
            : healthscores.avg_health_score.toFixed(1)}
          <div className={classes.tooltip}>
            <Tooltip
              title={
                <p>
                  Long-term indicator of attitude to health.{" "}
                  <a
                    style={{
                      color: "var(--white)",
                      textDecoration: "underline",
                    }}
                    href="https://avi-health.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
            >
              <Button>
                <HelpCircleIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.avg_health_score}
        </div>
        <div className={classes.card__title}>
          {!healthscores.avg_health_index
            ? "N/A"
            : `${healthscores.avg_health_index.toFixed(0)}%`}
          <div className={classes.tooltip}>
            <Tooltip
              title={
                <p>
                  Daily indicator of attitude to health.{" "}
                  <a
                    style={{
                      color: "var(--white)",
                      textDecoration: "underline",
                    }}
                    href="https://avi-health.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
            >
              <Button>
                <HelpCircleIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.avg_health_index}
        </div>
      </div>
    </Card>
  );
};
