import { Button, Layout, Modal } from "antd";
import {
  CustomerServiceOutlined,
  LayoutOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ReactNode, useState } from "react";
import { getAuth, signOut } from "firebase/auth";

import classes from "./styles/layout.module.scss";
import icon from "../../images/auth/icon.png";
import { locals } from "../../../servises/locals";
import { useNavigate } from "react-router-dom";
import { TarrifsOutlinedIcon } from "../../images/auth/svg";
import { reset } from "../../../servises/store/slice/subscriptionSlice";
import { useDispatch } from "react-redux";

const { Sider, Content } = Layout;

interface ILayoutProps {
  children: ReactNode;
}

export const BaseLayout = ({ children }: ILayoutProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const logoutHandler = async () => {
    const auth = getAuth();

    try {
      const res = await signOut(auth);
      localStorage.setItem("company_ref_id", "");
      localStorage.setItem("user_uid", "");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout hasSider={true} className={classes.layout_body}>
      <Sider
        trigger={null}
        className={classes.layout_sider}
        collapsible
        width={"100px"}
        collapsed={false}
      >
        <div className={classes.layout_logo}>
          <img src={icon} alt="" />
        </div>
        <div className={classes.layout_menu}>
          <Button
            className={classes.layout_icon}
            type="link"
            onClick={() => {
              navigate("/");
            }}
          >
            <LayoutOutlined />
            {locals.title.dashboard}
          </Button>
          <Button
            onClick={() => navigate("/help")}
            className={classes.layout_icon}
            type="link"
          >
            <CustomerServiceOutlined />
            Help
          </Button>
          <Button
            onClick={() => {
              navigate("/settings");
            }}
            className={classes.layout_icon}
            type="link"
          >
            <SettingOutlined />
            Settings
          </Button>

          <Button
            className={classes.layout_icon}
            type="link"
            onClick={() => {
              navigate("/payboard");
            }}
          >
            <TarrifsOutlinedIcon />
            Tariffs
          </Button>
        </div>
        <Button
          className={classes.layout_logout}
          type="link"
          onClick={() => {
            setOpen(true);
          }}
        >
          <UploadOutlined />
        </Button>
      </Sider>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={logoutHandler}
      >
        <h1
          style={{
            marginTop: "16px",
            fontFamily: "Lato-700",
            fontWeight: 700,
            fontSize: "34px",
            lineHeight: "41px",
          }}
        >
          {locals.title.signout}
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <Button
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",

              width: "218px",
              height: "66px",

              background: "#2B42A8",
              borderRadius: "12px",
              color: "white",
              fontFamily: "Lato-700",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "24px",
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            {locals.signout.no}
          </Button>
          <Button
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",

              width: "218px",
              height: "66px",
              border: "1px solid #F1F1F4",
              background: "white",
              borderRadius: "12px",
              color: "#101012",
              fontFamily: "Lato-700",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "24px",
            }}
            onClick={() => {
              const auth = getAuth();
              signOut(auth)
                .then(() => {
                  // Sign-out successful.
                  localStorage.setItem("company_ref_id", "");
                  localStorage.setItem("user_uid", "");
                  navigate("/login");
                  dispatch(reset());
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            {locals.signout.yes}
          </Button>
        </div>
      </Modal>
      <Content className={classes.layout_content}>{children}</Content>
    </Layout>
  );
};
