import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../dashboard";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AuthContext, FirebaseContext } from "../../App";
import { collection, getDocs, query, where } from "firebase/firestore";

export const Root = () => {
  const navigate = useNavigate();

  const db = useContext(FirebaseContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        localStorage.setItem("user_uid", user.uid);
        const company = query(
          collection(db, "/companies"),
          where("admin_user_uid", "==", user.uid)
        );
        const querySnapshot = await getDocs(company);
        querySnapshot.forEach((item) => {
          localStorage.setItem("company_ref_id", item.id);
        });
      }
    });
  }, []);

  return (
    <>
      <Dashboard />
    </>
  );
};
