import { ReactNode } from "react";
import classes from "./styles/backgroundCard.module.scss";

interface IBackgroundCard {
  children: ReactNode;
}

export const BackgroundCard = ({ children }: IBackgroundCard) => {
  return <div className={classes.background_card__body}>{children}</div>;
};
