import classes from "./styles/column.module.scss";

export interface IColumnProps {
  subTitle: string;
  percents: number;
}

export const Column = ({ percents, subTitle }: IColumnProps) => {
  return (
    <div className={classes.column__container}>
      <div className={classes.column__body}>
        <p className={classes.column__title}>{percents || 0}%</p>
        <div
          style={{ height: `${percents}%` }}
          className={classes.column__progress}
        ></div>
      </div>
      <p className={classes.column__subTitle}>{subTitle}</p>
    </div>
  );
};
