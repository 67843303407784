import { Button } from "antd";
import { ReactNode } from "react";
import classes from "./styles/baseButton.module.scss";

interface IBasePrimaryButtonProps {
  children: ReactNode;
  disabled?: boolean;
}

export const BasePrimaryButton = ({
  children,
  disabled = false,
}: IBasePrimaryButtonProps) => {
  return (
    <Button
      htmlType="submit"
      disabled={disabled}
      type="primary"
      className={classes.base_button}
    >
      {children}
    </Button>
  );
};
