import { Button, Spin } from "antd";
import classes from "./styles.module.scss";
import { BaseInput } from "../../../assets/components/input/BaseInput";
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { useFormik } from "formik";
import { useState } from "react";
import { object, string, ref } from "yup";
import { locals } from "../../../servises/locals";
import { errorHandler } from "../../../utils";
import { AuthErrorEnum } from "../../../type/auth";

export const UpdatePasswordModal = ({ onSubmit }: { onSubmit: () => void }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: object({
      new_password: string().required(),
      password: string().required(),
      confirm_password: string()
        .required()
        .oneOf([ref("new_password")], locals.auth.passwordMissmatch),
    }),

    onSubmit: async ({ password, new_password }) => {
      setLoading(true);
      const auth = getAuth();

      setMessage("");
      try {
        const authRes = await signInWithEmailAndPassword(
          auth,
          auth.currentUser?.email || "",
          password
        );
        if (auth.currentUser) {
          const res = await updatePassword(auth.currentUser, new_password);
          setLoading(false);
          onSubmit();
          formik.resetForm();
        }
      } catch (error: any) {
        setMessage(errorHandler(error.code as AuthErrorEnum));
        setLoading(false);
      }
    },
  });

  return (
    <div className={classes.modalContent}>
      <h1>Change the password</h1>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.input}>
          <BaseInput
            type="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            valid={!(formik.errors.password && formik.touched.password)}
          />
        </div>
        <div className={classes.input}>
          <BaseInput
            type="password"
            name="new_password"
            label="New password"
            value={formik.values.new_password}
            onChange={formik.handleChange}
            valid={!(formik.errors.new_password && formik.touched.new_password)}
          />
        </div>
        <div className={classes.input}>
          <BaseInput
            type="password"
            name="confirm_password"
            label="Confirm password"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            valid={
              !(
                formik.errors.confirm_password &&
                formik.touched.confirm_password
              )
            }
          />
        </div>
        <p>{message}</p>
        <Button disabled={loading} type="primary" htmlType="submit">
          {loading ? <Spin /> : "Change"}
        </Button>
      </form>
    </div>
  );
};
