import classes from "./styles.module.scss";

export const PlusMinusAnimated = ({ isActive }: { isActive?: boolean }) => {
  return (
    <div
      className={
        isActive
          ? `${classes.active} ${classes.plusMinus}`
          : `${classes.plusMinus}`
      }
    >
      <span></span>
      <span></span>
    </div>
  );
};
