import { NO_DATA } from "../../../servises/locals";

export const NoData = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--gray)",
      }}
    >
      {NO_DATA}
    </div>
  );
};
