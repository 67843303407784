import { Button, Spin } from "antd";
import classes from "./styles.module.scss";
import { BaseInput } from "../../../assets/components/input/BaseInput";
import {
  doc,
  updateDoc,
} from "@firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { object, string } from "yup";
import { FirebaseContext } from "../../../App";
import { errorHandler } from "../../../utils";
import { AuthErrorEnum } from "../../../type/auth";

export const UpdateNameModal = ({ onSubmit }: { onSubmit: () => void }) => {
  const db = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const auth = getAuth();
  const companyRefId = localStorage.getItem("company_ref_id") || "";

  const formik = useFormik({
    initialValues: {
      password: "",
      name: "",
    },
    validationSchema: object({
      name: string().required(),
      password: string().required(),
    }),

    onSubmit: async ({ password, name }) => {
      setMessage("");
      setLoading(true);
      try {
        const authRes = await signInWithEmailAndPassword(
          auth,
          auth.currentUser?.email || "",
          password
        );
        if (auth.currentUser) {
          const res = await updateDoc(doc(db, "companies", companyRefId), {
            name,
          });
          setLoading(false);
          onSubmit();
          formik.resetForm();
        }
      } catch (error: any) {
        setMessage(errorHandler(error.code as AuthErrorEnum));
        setLoading(false);
      }
    },
  });

  return (
    <div className={classes.modalContent}>
      <h1>Change the company name</h1>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.input}>
          <BaseInput
            type="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            valid={!(formik.errors.password && formik.touched.password)}
          />
        </div>
        <div className={classes.input}>
          <BaseInput
            type="name"
            name="name"
            label="Company name"
            value={formik.values.name}
            onChange={formik.handleChange}
            valid={!(formik.errors.name && formik.touched.name)}
          />
        </div>
        <p>{message}</p>
        <Button disabled={loading} type="primary" htmlType="submit">
          {loading ? <Spin /> : "Change"}
        </Button>
      </form>
    </div>
  );
};
