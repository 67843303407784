import { Button } from "antd";
import { ILinkButtonProps } from "./propTypes";
import classes from "./styles/baseButton.module.scss";

export const LinkButton = ({ href, name }: ILinkButtonProps) => {
  return (
    <Button href={href} type="link" className={classes.base_button}>
      {name}
    </Button>
  );
};
