import { ContactForm } from "../contactForm";
import { HelpAccordion } from "./HelpAccordion";
import classes from "./styles.module.scss";

export const HelpContent = () => {
  return (
    <div className={classes.helpContainer}>
      <HelpAccordion />
      <ContactForm />
    </div>
  );
};
