import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dateToLocalDateString, getDb, getMeasureSystem } from "../../../utils";
import { IFilter } from "../../../routes/dashboard";
import { collection, doc, getDocs, query, where } from "@firebase/firestore";
import { RootState } from "..";
import { ACTIVITY, MOOD, NUTRITION } from "../../../data/categories";
import { IUserAnalytics } from "../../../type/user_analytics";
import dayjs from "dayjs";

export const flattenObject = (obj: any) => {
  const flattened: any = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

const applyHealthScores = (data: IUserAnalytics[]) => {
  const getAvg = () => {
    return data.reduce(
      (acc, curr) => {
        const avgHI = curr.values.avg_health_index;
        const total = avgHI ? acc.total + avgHI : acc.total;
        const count = avgHI ? acc.count + 1 : acc.count;

        return { count, total };
      },
      { count: 0, total: 0 }
    );
  };
  return {
    cooper_apanasenko: data[data.length - 1].values.cooper_apanasenko,
    avg_health_index: getAvg().total / getAvg().count,
    avg_health_score: data[data.length - 1].values.avg_health_score,
  };
};

const applyAnalyticsData = (data: any[], tab: string) => {
  return data.map((item) => {
    return {
      date: item.date,
      value: item.values[tab.toLowerCase()] || null,
    };
  });
};

const applyCategoryAnalyticsData = (
  data: any[],
  category: string,
  tab: string
) => {
  return data.map((item) => {
    return {
      date: item.date,
      value: item.values[category][tab.toLowerCase()] || null,
    };
  });
};

export const getUsersAnalyticsThunk = createAsyncThunk(
  "getUsersAnalytics",
  async ({ filter, tab }: { filter: IFilter; tab: string }) => {
    const db = getDb();
    const { period } = filter;

    let dateRange = period;
    if (period[0].$d && period[1].$d) {
      dateRange = [dayjs(period[0]).subtract(1, "days").toDate(), period[1].$d];
    }

    let data: IUserAnalytics[] = [];
    try {
      const usersAnalyticsCollection = query(
        collection(db, "users_analytics"),
        where("user_ref", "==", doc(db, "users", filter.currentUser)),
        where("report_date", ">", dateRange[0]),
        where("report_date", "<", dateRange[1])
      );

      const docs = await getDocs(usersAnalyticsCollection);
      docs.forEach((item) => {
        if (item.data().values) {
          const date = dateToLocalDateString(item.data().report_date, "en-EN");
          data.push({
            date,
            id: item.data().uid,
            age: item.data().age,
            gender: item.data().gender,
            is_active_today: item.data().is_active_today,
            values: item.data().values,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }

    return { data, tab };
  }
);

interface IAnalyticsData {
  value: number | null;
  date: string;
}


interface IUserAnalyticsSlice {
  usersAnalytics: IUserAnalytics[];
  analyticsData: IAnalyticsData[];
  loading: boolean;
  moodAnalyticsData: IAnalyticsData[];
  nutritionAnalyticsData: IAnalyticsData[];
  activityAnalyticsData: IAnalyticsData[];
  healthScores: {
    cooper_apanasenko: number | null | string;
    avg_health_index: number | null;
    avg_health_score: number | null;
  };
}

const initialState: IUserAnalyticsSlice = {
  usersAnalytics: [],
  analyticsData: [],
  moodAnalyticsData: [],
  nutritionAnalyticsData: [],
  activityAnalyticsData: [],
  loading: false,
  healthScores: {
    cooper_apanasenko: 0,
    avg_health_index: 0,
    avg_health_score: 0,
  },
};

const userAnalyticsSlice = createSlice({
  name: "userAnalyticsSlice",
  initialState,
  reducers: {
    setAnalyticsData(state, { payload }: PayloadAction<string>) {
      state.analyticsData = applyAnalyticsData(state.usersAnalytics, payload);
    },
    setBodyAnalyticsData(state, { payload }: PayloadAction<string>) {
      state.analyticsData = applyCategoryAnalyticsData(
        state.usersAnalytics,
        "body",
        payload
      );
    },
    setMoodAnalyticsData(state, { payload }: PayloadAction<string>) {
      state.moodAnalyticsData = applyCategoryAnalyticsData(
        state.usersAnalytics,
        "mood",
        payload
      );
    },
    setNutritionAnalyticsData(state, { payload }: PayloadAction<string>) {
      state.nutritionAnalyticsData = applyCategoryAnalyticsData(
        state.usersAnalytics,
        "food",
        payload
      );
    },
    setActivityAnalyticsData(state, { payload }: PayloadAction<string>) {
      state.activityAnalyticsData = applyCategoryAnalyticsData(
        state.usersAnalytics,
        "activity",
        payload
      );
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAnalyticsThunk.fulfilled, (state, { payload }) => {
        state.usersAnalytics = payload.data;
        state.analyticsData = applyAnalyticsData(payload.data, payload.tab);
        state.healthScores = applyHealthScores(payload.data);
        state.moodAnalyticsData = applyCategoryAnalyticsData(
          payload.data,
          "mood",
          MOOD[0].value
        );
        state.nutritionAnalyticsData = applyCategoryAnalyticsData(
          payload.data,
          "food",
          NUTRITION(getMeasureSystem())[0].value
        );
        state.activityAnalyticsData = applyCategoryAnalyticsData(
          payload.data,
          "activity",
          ACTIVITY[0].value
        );
        state.loading = false;
      })
      .addCase(getUsersAnalyticsThunk.pending, (state) => {
        state.loading = true;
      });
  },
});

export const selectUsersAnalytics = (state: RootState) => state.usersAnalytics;

export const {
  reset,
  setAnalyticsData,
  setBodyAnalyticsData,
  setActivityAnalyticsData,
  setMoodAnalyticsData,
  setNutritionAnalyticsData,
} = userAnalyticsSlice.actions;
export default userAnalyticsSlice.reducer;
