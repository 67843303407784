import { Button } from "antd";
import classes from "../../styles/subtitleDashboard.module.scss";

//userIndex + 1
interface IInvintationItem {
  invitation: any;
  addUser: (param: string) => void;
  rejectUser: (param: string) => void;
  blockUser: (param: string) => void;
  index: number;
}

export const InvintationItem = ({
  addUser,
  invitation,
  rejectUser,
  index,
  blockUser,
}: IInvintationItem) => {
  return (
    <div className={classes.invintetion_modal__user}>
      <div className={classes.invintetion_modal__user_number}>{index}</div>
      <p className={classes.invintetion_modal__user_name}>{invitation.user_name}</p>
      <p className={classes.invintetion_modal__user_email}>{invitation.user_email}</p>
      <Button
        type="primary"
        onClick={() => {
          addUser(invitation.id);
        }}
      >
        Add
      </Button>

      <Button
        type="default"
        onClick={() => {
          rejectUser(invitation.id);
        }}
      >
        Decline
      </Button>

      <Button onClick={() => blockUser(invitation.id)} type="primary" danger>
        Block
      </Button>
    </div>
  );
};
