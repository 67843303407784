import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import subscription from "./slice/subscriptionSlice";
import mainCompanyChart from "./slice/mainCompanyChartSlice";
import usersAnalytics from "./slice/userAnalyticsSlice";
import companyAnalytics from "./slice/companyAnalyticsSlice";

const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    subscription,
    mainCompanyChart,
    usersAnalytics,
    companyAnalytics,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["meta.arg", "payload.period", "payload.filter"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
