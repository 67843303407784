import { Col, Row } from "antd";
import { useContext, useEffect } from "react";

import { BackgroundCard } from "../../../assets/components/display/BackgroundCard";
import { HealthScore } from "./healthscore/HeathScore";
import common_classes from "../styles/dashboard.module.scss";
import { MainChart } from "./mainChart";
import { FilterContext, FilterUserEnum } from "..";
import { UserInformation } from "./information/UserInformation";
import { CompayInformation } from "./information/CompanyInformation";

export const MainDashboardComponent = () => {
  const { filter } = useContext(FilterContext);

  return (
    <BackgroundCard>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className={common_classes.dashboard_column} span={13}>
          <MainChart />
        </Col>
        <Col span={4}>
          {filter.currentUser && filter.user === FilterUserEnum.USER ? (
            <UserInformation />
          ) : (
            <CompayInformation />
          )}
        </Col>
        <Col span={7}>
          <HealthScore />
        </Col>
      </Row>
    </BackgroundCard>
  );
};
