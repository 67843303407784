import { Collapse } from "antd";
import { PlusMinusAnimated } from "../plusMinusAnimated";
import classes from "./styles.module.scss";
const { Panel } = Collapse;

const FAQS_DATA = [
  {
    header: "What is Health Score and what is it for?",
    id: "1",
    paragraph:
      "Avi Health score is a joint development of health professionals from Russia, Israel, Spain, the Netherlands, and the United States. It is an accurate indicator of your attitude to health: the higher the score, the more likely you are to live a long and happy life and to remain an active member of society for as long as possible. Health score is calculated for the whole year, but after 7 days of using the app, we will give you predictions on the annual result as well as tips on how to improve it.",
  },
  {
    header: "What is the Health Index?",
    id: "2",
    paragraph:
      "The Health Index shows how healthy you are each day. It is influenced by your activity levels, dietary balance, emotional well-being, and unhealthy habits. For ease of reference, the Health Index is calculated in %, where 100% means you did everything in your power to help your body stay as healthy as possible for as long as possible.",
  },
  {
    header: "How does the Health Score work?",
    id: "3",
    paragraph:
      "The Avi Health Score algorithms take into account the individual characteristics of each user (gender, height, weight, age, physical condition, presence of chronic and acquired diseases) and compare them to what the person does for his or her health every day (how active he or she is, diet, emotional background, how often the body diangostics).",
  },
  {
    header: "How do I improve my Health Score?",
    id: "4",
    paragraph:
      "Stick to the Activity and Nutrition guidelines listed in the appendix, monitor your emotional state, and have your health screenings on time. The closer to normal your results are, the higher your Health Score will be.",
  },
  {
    header: "How do I change my Health Score?",
    id: "5",
    paragraph:
      "The Activity and Nutrition section shows rates based on the data you entered when you registered. You can change the norms in the Settings for each metric. The rates are also recalculated each time you update your weight and fitness information. If you entered your data incorrectly during registration, you can enter it again under Settings - User Data - Calculate Norms.",
  },
  {
    header: "How can I use Avi Health to find out how fit I am?",
    id: "6",
    paragraph:
      "In the Medical Chart section, you will be asked to take two fitness tests: the Cooper Test and the Apanasenko Test. The Cooper Test is designed for people who are into sports such as running, swimming or cycling. The main goal is to run a longer distance in 12 minutes. The Aponasenko test is often done in a hospital setting, but if you have a blood pressure monitor, spirometer and dynamometer, it can also be done at home. If you take one of the tests, we will recalculate your Activity and Nutritional Standards.",
  },
];

export const HelpAccordion = () => {
  const panelStlye = {
    marginBottom: 20,
    background: "var(--light-blue)",
    borderRadius: 16,
    border: 0,
    color: "var(--blue)",
    padding: "8px  6px",
  };
  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <PlusMinusAnimated isActive={isActive} />}
      expandIconPosition="end"
      defaultActiveKey="1"
      className={classes.collapse}
      style={{
        fontSize: 22,
        color: "var(--blue)",
      }}
    >
      {FAQS_DATA.map((item) => (
        <Panel
          style={panelStlye}
          key={item.id}
          header={<div className={classes.collapseHeader}>{item.header}</div>}
        >
          <div className={classes.collapseBody}>{item.paragraph}</div>
        </Panel>
      ))}
    </Collapse>
  );
};
