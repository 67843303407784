import { DownloadOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import classes from "./styles.module.scss";
import { selectSubscriptionSlice } from "../../../servises/store/slice/subscriptionSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FilterContext } from "../../../routes/dashboard";
import { timestampToDate, toExcelDate } from "../../../utils";
import { useGetUsers } from "../../../hooks/useGetUsers";
import { query, collection, doc, getDocs, where } from "@firebase/firestore";
import { FirebaseContext } from "../../../App";
import dayjs from "dayjs";

const flattenObject = (obj: any) => {
  const flattened: any = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

export const ExportToExcel = () => {
  const { paystatus } = useSelector(selectSubscriptionSlice);
  const { filter } = useContext(FilterContext);
  const db = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const { company } = useSelector(selectSubscriptionSlice);
  const { usersCollection } = useGetUsers();
  const getUserAnalyticsData = async (userId: string): Promise<any[]> => {
    const { period } = filter;

    let dateRange = period;
    if (period[0].$d && period[1].$d) {
      dateRange = [dayjs(period[0]).subtract(1, "days").toDate(), period[1].$d];
    }
    const usersAnalyticsCollection = query(
      collection(db, "users_analytics"),
      where("user_ref", "==", doc(db, "users", userId)),
      where("report_date", ">", dateRange[0]),
      where("report_date", "<", dateRange[1])
    );
    let userAnalytics: any[] = [];
    const usersAnalyticsDocs = await getDocs(usersAnalyticsCollection);

    usersAnalyticsDocs.forEach((item) => {
      if (item.data().values) {
        userAnalytics.push(item.data());
      }
    });
    return userAnalytics;
  };

  const mapData = async () => {
    return usersCollection.map(async ({ user }) => {
      const userAnalytics = await getUserAnalyticsData(user.uid);
      const mainInfo = {
        "REGISTRATION DATE": timestampToDate({
          seconds: user.creationTime,
          nanoseconds: 0,
        }).toLocaleDateString("en-GB"),
        EMAIL: user.email,
        NAME: user.name,
        GENDER: user.gender,
        BIRTHDAY: timestampToDate(user.birthDate).toLocaleDateString("en-GB"),
        HEIGHT: user.userHeight,
        WEIGHT: user.userWeight,
      };

      if (!userAnalytics.length) {
        return mainInfo;
      }

      return userAnalytics.map((item) => {
        return {
          ...mainInfo,
          "DATA ENTRY DATE": timestampToDate(
            item.report_date
          ).toLocaleDateString("en-GB"),
          "HEALTH INDEX": item.values.avg_health_index,
          "HEALTH SCORE": item.values.avg_health_score,
          ENERGY: item.values.activity.calories_burned,
          STEPS: item.values.activity.steps,
          "STAND MIN": item.values.activity.activity_time_min,
          SLEEP: item.values.activity.sleep_time_min / 60 || null,
          CALLORIES: item.values.food.calories,
          PROTEIN: item.values.food.proteins_grams,
          FATS: item.values.food.fats_grams,
          CARBOHYDRATES: item.values.food.carbohydrates_grams,
          WATER: item.values.food.water_liters,
          "GENERAL MOOD": item.values.mood.common,
          FEELING: item.values.mood.well_being,
          "EXT FACTORS": item.values.mood.outside_factors,
          FAMILY: item.values.mood.family,
          WORK: item.values.mood.job,
          WEIGHT: item.values.weight,
          CHEST: item.values.body.breast,
          HIPS: item.values.body.hip,
          WAIST: item.values.body.waist,
          SMOKING: item.values.smoking,
          ALCOHOL: item.values.alcohol,
        };
      });
    });
  };

  const exportToCSV = async () => {
    if (paystatus === "LITE") {
      setShow(true);
      return;
    }
    if (!paystatus) {
      navigate('/payboard')
      return;
    }
    const data = await mapData();

    Promise.all(data).then((res) => {
      const ws = XLSX.utils.json_to_sheet(res.flat(1));
      ws["!cols"] = res.flat(1).map((item) => {
        return {
          wpx: 110,
        };
      });
      const wb = {
        Sheets: { data: ws },
        SheetNames: ["data"],
      };

      XLSX.writeFile(
        wb,
        `${company.name}_${company.nickname} ${toExcelDate(
          filter.period[0]
        )}-${toExcelDate(filter.period[1])}.xlsx`
      );
    });
  };

  useEffect(() => {
    let timeout: any;
    if (show) {
      timeout = setTimeout(() => {
        setShow(false);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [show]);

  return (
    <>
      <button
        type="button"
        className={paystatus ? `${classes.download}` : `${classes.download} ${classes.disabled}`}
        onClick={(e) => exportToCSV()}
      >
        <DownloadOutlined /> DOWNLOAD IN EXCEL
      </button>
      {show && (
        <div onClick={() => navigate("/payboard")} className={classes.popup}>
          Not available for Lite subscription.
        </div>
      )}
    </>
  );
};
