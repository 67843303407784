import { memo, useContext, useEffect } from "react";
import { FilterContext, FilterUserEnum } from "../..";
import { Chart } from "../../../../assets/components/data/Chart";
import { firstLetterToUpperCase } from "../../../../utils";
import classes from "../../styles/dashboard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../servises/store";
import { Spin } from "antd";
import {
  getAllUsersAnalyticsThunk,
  setActivity,
} from "../../../../servises/store/slice/companyAnalyticsSlice";

const CompanyMainChartComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { filter } = useContext(FilterContext);
  const activity = useSelector(
    (state: RootState) => state.companyAnalytics.activity
  );
  const loading = useSelector(
    (state: RootState) => state.companyAnalytics.loading
  );

  const getChartTitle = () => {
    switch (filter.user) {
      case FilterUserEnum.ALL:
        return "Users";
      default:
        return firstLetterToUpperCase(filter.user);
    }
  };

  useEffect(() => {
    dispatch(getAllUsersAnalyticsThunk({ filter }));
  }, [filter.age, filter.period]);

  useEffect(() => {
    dispatch(setActivity(filter.user));
  }, [filter.user]);

  return (
    <>
      <div className={classes.companyMainChart}>
        <h3>{getChartTitle()} activity over time</h3>
        {loading ? (
          <div className={classes.loader}>
            <Spin />
          </div>
        ) : (
          <div className={classes.chart}>
            <Chart data={activity} height={350} />
          </div>
        )}
      </div>
    </>
  );
};

export const CompanyMainChart = memo(CompanyMainChartComponent);
