import { createBrowserRouter } from "react-router-dom";
import { Auth } from "./auth";
import { Login } from "./auth/login/Login";
import { PayBoard } from "./payboard/PayBoard";
import { Root } from "./root/Root";
import { Settings } from "./settings/Settings";
import { Help } from "./help";
import { ConfirmEmail } from "./confirm";
import { ResetPassword } from "./reset";
import { ResetConfirimPassword } from "./reset-confirm";

let testPromise = new Promise(function (resolve, reject) {
  resolve("Success");
  reject("Reject");
});

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: () => {
      return testPromise.then((res) => res);
    },
  },

  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/confirm-reset",
    element: <ResetConfirimPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/payboard",
    element: <PayBoard />,
  },
  {
    path: "/help",
    element: <Help />,
  },

  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/confirm",
    element: <ConfirmEmail />,
  },
]);
