import { createContext, useEffect, useMemo, useState } from "react";
import { BaseLayout } from "../../assets/components/display/Layout";
import { Header } from "../../assets/components/display/Header";
import { MainDashboardComponent } from "./components/MainDashboardComponent";
import { SecondDashboard } from "./components/SecondDashboardComponent";
import { SubtitleDashboard } from "./components/SubtitleDashboardComponent";
import {
  getCompaniesThunk,
  getSubcriptionInfoThunk,
} from "../../servises/store/slice/subscriptionSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../servises/store";
import classes from "./styles/dashboard.module.scss";

export enum FilterUserEnum {
  ALL = "all",
  MALE = "male",
  FEMALE = "female",
  USER = "user",
}

export interface IFilter {
  user: FilterUserEnum;
  age: [number, number];
  period: any;
  currentUser: string;
}

interface FilterContextType {
  filter: IFilter;
  setFilter: any;
}

export const FilterContext = createContext<FilterContextType>({
  filter: {
    user: FilterUserEnum.ALL,
    age: [0, 99],
    period: [null, null],
    currentUser: "",
  },
  setFilter: () => {},
});

export const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getCompaniesThunk());
    dispatch(getSubcriptionInfoThunk());
  }, []);

  const [filter, setFilter] = useState<IFilter>({
    user: FilterUserEnum.ALL,
    age: [18, 99],
    period: [null, null],
    currentUser: "",
  });

  const value = useMemo(() => ({ filter, setFilter }), [filter]);

  return (
    <FilterContext.Provider value={value}>
      {useMemo(
        () => (
          <BaseLayout>
            <Header title="Dashboard" />
            <div className={classes.subtitleDashboardVertical}>
              <SubtitleDashboard />
            </div>
            <div className={classes.mainDashboardWithFilters}>
              <div className={classes.mainDashboard}>
                <MainDashboardComponent />
              </div>
              <div className={classes.subtitleDashboard}>
                <SubtitleDashboard />
              </div>
            </div>
            <SecondDashboard />
          </BaseLayout>
        ),
        []
      )}
    </FilterContext.Provider>
  );
};
