import { BaseSyntheticEvent, useState } from "react";
import { BaseInput } from "../../assets/components/input/BaseInput";
import { AuthWrappedCardComponent } from "../auth/components/AuthWrappedCardComponent";
import { LinkButton } from "../../assets/components/input/LinkButton";
import { Button, Spin } from "antd";
import classes from "./styles.module.scss";
import { BasePrimaryButton } from "../../assets/components/input/BaseButton";
import { useFormik } from "formik";
import { object, string } from "yup";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const ResetPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");
  const auth = getAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: object({
      email: string().email().required(),
    }),

    onSubmit: async ({ email }) => {
      setMessage("");
      setLoading(true);
      try {
        await sendPasswordResetEmail(auth, email);
        setEmailSent(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setMessage("Something went wrong. Please try again later.");
      }
    },
  });

  return (
    <AuthWrappedCardComponent title="Password reset">
      {emailSent ? (
        <div className={classes.info}>
          <h4>
            Submitted.
            <br />
            Following instructions you can find in email that we've just sent
            you.
          </h4>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className={classes.input}>
          <BaseInput
            label="Email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            valid={!(formik.errors.email && formik.touched.email)}
          />
          <p>{message}</p>
          <div className={classes.controls}>
            <BasePrimaryButton disabled={loading}>{loading ? <Spin /> : "Next"}</BasePrimaryButton>
            <LinkButton name="Back" href="/login" />
          </div>
        </form>
      )}
    </AuthWrappedCardComponent>
  );
};
