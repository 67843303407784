import { Input } from "antd";
import { BaseSyntheticEvent } from "react";
import classes from "./styles/baseInput.module.scss";

interface IBaseInputProps {
  validationMessage?: string;
  touched?: boolean;
  label: string;
  id?: string;
  valid?: boolean;
  disabled?: boolean;
  hint?: string;
  name: string;
  type: string;
  value: string;
  onChange: (params: BaseSyntheticEvent) => void;
  onBlur?: any;
}

export const BaseInput = ({
  validationMessage,
  touched,
  label,
  id,
  valid,
  disabled,
  hint,
  type,
  value,
  onChange,
  name,
  onBlur,
}: IBaseInputProps) => {
  return (
    <fieldset className={classes.field_body}>
      {value && <label className={classes.base_label}>{label}</label>}
      <Input
        type={type}
        id={id}
        value={value}
        disabled={disabled}
        placeholder={label}
        className={classes.base_input}
        onChange={onChange}
        name={name}
        status={valid ? "" : "error"}
        onBlur={onBlur}
        autoComplete="off"
      />
      {validationMessage}
    </fieldset>
  );
};
