export const MOOD = [
  {
    title: "General",
    value: "common",
    measurement: "",
  },
  {
    title: "Feeling",
    value: "well_being",
    measurement: "",

  },
  {
    title: "Ext factors",
    value: "outside_factors",
    measurement: "",

  },
  {
    title: "Family",
    value: "family",
    measurement: "",

  },
  {
    title: "Work",
    value: "job",
    measurement: "",

  },
];

export const ACTIVITY = [
  {
    title: "Energy",
    value: "calories_burned",
    measurement: "Kcal",
  },
  {
    title: "Steps",
    value: "steps",
    measurement: "",

  },
  {
    title: "Stand min",
    value: "activity_time_min",
    measurement: "min",
  },
  {
    title: "Sleep",
    value: "sleep_time_min",
    measurement: "hours",
  },
];

export const NUTRITION = (measureSystem: string) => [
  {
    title: "Calories",
    value: "calories",
    measurement: "Kcal",
  },
  {
    title: "Protein",
    value: "proteins_grams",
    measurement: "g",
  },
  {
    title: "Fats",
    value: "fats_grams",
    measurement: "g",
  },
  {
    title: "Carbo",
    value: "carbohydrates_grams",
    measurement: "g",
  },
  {
    title: "Water",
    value: "water_liters",
    measurement: measureSystem === "US" ? "oz" : "l",
  },
];
