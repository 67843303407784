import { Line } from "@ant-design/plots";
import { LineConfig } from "@ant-design/charts";

export interface IChartValue {
  date: string;
  value: number | null;
}

interface IChartProps {
  data: IChartValue[];
  height?: number;
  measurement?: string;
}

export const Chart = ({ data, height, measurement }: IChartProps) => {
  const config: LineConfig = {
    data: data.map((item) => {
      return {
        ...item,
        value: item.value === null ? null : +item.value.toFixed(1),
      };
    }),
    connectNulls: true,
    height: height ? height : 400,
    xField: "date",
    yField: "value",
    point: {
      size: 5,
      shape: "",
      style: {
        fill: "white",
        stroke: "#5B69AA",
        lineWidth: 3,
      },
    },

    meta: {
      value: {
        min: Math.min.apply(
          null,
          data.map((item) => item.value as number)
        ),
        max:
          Math.max.apply(
            null,
            data.map((item) => item.value as number)
          ) +
          0.15 *
            Math.max.apply(
              null,
              data.map((item) => item.value as number)
            ),

        ticks: [0, 0],
      },
    },
    padding: 18,
    smooth: true,
    tooltip: {
      showMarkers: false,
      showTitle: false,
      enterable: false,
      position: "right",
      customContent(title, data) {
        return data.length !== 0 ? data[0].value : null;
      },
      domStyles: {
        "g2-tooltip": {
          backgroundColor: "#2B42A8",
          width: "53px",
          height: "41px",
          color: "white",
          borderRadius: "57px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Lato-700",
          fontSize: "14px",
          lineHeight: "17px",
        },
      },
      crosshairs: {
        line: {
          style: {
            stroke: "#0F1322",
            fillOpacity: 0,
            lineJoin: "round",
            lineDash: [1, 10],
            lineCap: "round",
          },
        },
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 1400,
          fill: "#5B69AA",
          opacity: 0,
        },
      },
    },

    lineStyle: {
      lineWidth: 3,
      stroke: "#5B69AA",
      x: 0,
    },

    yAxis: {
      tickMethod: "cat",
      label: {
        style: {
          fill: "#B8BAC3",
          fontSize: 14,
        },
      },
      position: "right",
      title: {
        text: measurement || "",
        position: "end",
        autoRotate: false,
        offset: -2,
        style: {
          fontSize: 14,
          fill: "#B8BAC3",
        },
      },
    },
    xAxis: {
      grid: {
        line: {
          style: {
            stroke: "black",
            strokeOpacity: 0,
            fillOpacity: 0,
            opacity: 1,
          },
        },
      },
    },
  };
  return (
    <div>
      <Line {...config} />
    </div>
  );
};
