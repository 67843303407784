import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { doc, getDoc } from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ICompanyRes } from "../../../type/company";
import { getDb } from "../../../utils";

interface SubscriptionSlice {
  company: ICompanyRes;
  paystatus: any;
  subscription: any;
  loaded: boolean;
}

export const getCompaniesThunk = createAsyncThunk("getCompanies", async () => {
  const db = getDb();
  const companyRefId = localStorage.getItem("company_ref_id") || "";
  let data;
  try {
    const companies = doc(db, "companies", companyRefId);
    data = await getDoc(companies);
  } catch (error) {
    console.log(error);
  }
  return { data: data?.data() as ICompanyRes };
});

export const getSubcriptionInfoThunk = createAsyncThunk(
  "getSubscriptionInfo",
  async () => {
    const db = getDb();
    let subscription: any;
    let paystatus;
    try {
      const functions = getFunctions();

      const addMessage = httpsCallable(
        functions,
        "findCompanyCurrentSubscription"
      );

      const res = await addMessage({
        company_id: localStorage.getItem("company_ref_id"),
      });

      subscription = res.data;

      if (!subscription.subscription) {
        paystatus = null;
        return;
      }

      const companyPricingPlanDoc = doc(
        db,
        "company_pricing_plans",
        subscription.subscription.pricing_plan_id
      );

      const companyPricingPlan = (
        await getDoc(companyPricingPlanDoc)
      ).data() as {
        plan: string;
      };

      paystatus = companyPricingPlan.plan;
    } catch (error) {
      console.log(error);
    }
    return { subscription: subscription.subscription, paystatus };
  }
);

const initialState: SubscriptionSlice = {
  company: {
    name: "",
    nickname: "",
    admin_user_uid: "",
  },
  paystatus: null,
  subscription: undefined,
  loaded: false,
};

export const subscriptionSlice = createSlice({
  name: "subscriptionsSlice",
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesThunk.fulfilled, (state, { payload }) => {
        state.company = payload.data;
      })
      .addCase(getSubcriptionInfoThunk.fulfilled, (state, { payload }) => {
        state.loaded = true;
        if (payload) {
          state.subscription = payload.subscription;
          state.paystatus = payload.paystatus;
        }
      });
  },
});
export const { reset } = subscriptionSlice.actions;
export const selectSubscriptionSlice = (state: RootState) => state.subscription;

export default subscriptionSlice.reducer;
