import { memo, useContext, useEffect, useState } from "react";
import { Chart } from "../../../../assets/components/data/Chart";
import { Card } from "../../../../assets/components/display/Card";
import { Button, Tooltip } from "antd";
import classes from "./styles.module.scss";
import { applyMeasureSystem } from "../../../../utils";
import { HelpCircleIcon } from "../../../../assets/images/auth/svg";
import { ACTIVITY } from "../../../../data/categories";
import { FilterContext } from "../..";
import { selectSubscriptionSlice } from "../../../../servises/store/slice/subscriptionSlice";
import { useSelector } from "react-redux";
import { NoData } from "../../../../assets/components/display/NoData";

interface IUserCategoryChartProps {
  onChangeCategory: (category: string) => void;
  title: string;
  chartData: {
    date: string;
    value: any;
  }[];
  categories: {
    title: string;
    value: string;
    measurement?: string;
  }[];
  tooltipText: string;
}

export const UserCategoryChartComponent = ({
  onChangeCategory,
  title,
  categories,
  chartData,
  tooltipText,
}: IUserCategoryChartProps) => {
  const { subscription } = useSelector(selectSubscriptionSlice);
  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const { filter } = useContext(FilterContext);
  const changeCategoryHandler = (categoryValue: string) => {
    const activeCategoryToFind = categories.find(
      (category) => category.value === categoryValue
    );

    if (activeCategoryToFind) {
      setActiveCategory(activeCategoryToFind);
    }
    onChangeCategory(categoryValue);
  };

  const minsToHoursMap = () => {
    if (activeCategory === ACTIVITY[3]) {
      return chartData.map((item) => {
        return {
          ...item,
          value: item.value / 60 || null,
        };
      });
    }

    return chartData;
  };

  useEffect(() => {
    setActiveCategory(categories[0]);
  }, [filter.currentUser])

  return (
    <Card fullHeight>
      <div className={classes.cardContent}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3>{title}</h3>
            <div className={classes.tooltip}>
              <Tooltip title={tooltipText}>
                <Button>
                  <HelpCircleIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className={classes.userCategoriesTabs}>
            {categories.map((category) => (
              <div key={category.value} className={classes.item}>
                <Button
                  className={
                    activeCategory.value === category.value
                      ? `${classes.active}`
                      : `${classes.button}`
                  }
                  onClick={() => changeCategoryHandler(category.value)}
                >
                  {category.title}
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.chart}>
          {subscription ?
            <Chart
              data={applyMeasureSystem(
                minsToHoursMap(),
                "volume",
                activeCategory.value === "water_liters"
              )}
              height={230}
              measurement={activeCategory.measurement}
            />
            : <NoData />
          }
        </div>
      </div>
    </Card>
  );
};

export const UserCategoryChart = memo(UserCategoryChartComponent);
