import { DatePicker, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import classes from "../../styles/subtitleDashboard.module.scss";
import { FilterContext } from "../..";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { getAuth } from "firebase/auth";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const SubtitleDatePicker = () => {
  const auth = getAuth();
  const { filter, setFilter } = useContext(FilterContext);
  const now = dayjs().subtract(1, "days");
  const lastWeekDate = dayjs().subtract(8, "days");
  const [range, setRange] = useState<RangeValue>([lastWeekDate, now]);

  useEffect(() => {
    if (range) {
      if (range[0] && range[1]) {
        setFilter({ ...filter, ...{ period: range } });
      }
    }
  }, [range]);

  const changeDateHandler = (event: any) => {
    if (event) {
      setRange(event);
    }
  };

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        disabledDate={(current) =>
          current.isAfter(dayjs().subtract(1, "day")) ||
          current.isBefore(
            dayjs(auth.currentUser?.metadata.creationTime).subtract(1, "day")
          )
        }
        allowEmpty={[true, true]}
        format="DD-MM-YYYY"
        size={"large"}
        className={classes.rangePicker}
        onChange={changeDateHandler}
        defaultValue={[lastWeekDate, now]}
      />
    </Space>
  );
};
