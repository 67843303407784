import { Button, Input, Modal } from "antd";

import classes from "./Settings.module.scss";
import { useState } from "react";
import { UpdateEmailModal } from "./modals/UpdateEmailModal";
import { UpdatePasswordModal } from "./modals/UpdatePasswordModal";
import { UpdateNameModal } from "./modals/UpdateNameModal";

interface ISettingsInput {
  value?: string;
  name: string;
  type: string;
  onSubmit: () => void;
  editable?: boolean;
}

export const SettingsInput = ({
  name,
  value,
  type,
  onSubmit,
  editable = true,
}: ISettingsInput) => {
  const [disabled, setDisabled] = useState(true);
  const switchToEditHandler = () => {
    setDisabled(false);
  };

  const closeHandler = () => {
    setDisabled(true);
  };

  const submitHandler = () => {
    closeHandler();
    onSubmit();
  };

  return (
    <div className={classes.input_body}>
      <Input
        className={classes.input}
        value={value}
        disabled={true}
        placeholder={name === "Password" ? "********" : name}
        name={name}
        type={type}
      />
      {editable && (
        <Button
          onClick={switchToEditHandler}
          type="text"
          className={classes.button}
        >
          Change
        </Button>
      )}

      <Modal
        width={536}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={!disabled}
        onCancel={closeHandler}
      >
        {name === "Email" && <UpdateEmailModal onSubmit={submitHandler} />}
        {name === "Name" && <UpdateNameModal onSubmit={submitHandler} />}
        {name === "Password" && (
          <UpdatePasswordModal onSubmit={submitHandler} />
        )}
      </Modal>
    </div>
  );
};
