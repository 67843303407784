import { FilterContext, FilterUserEnum } from "..";
import React, { useContext, useEffect, useState } from "react";
import { doc, getDoc } from "@firebase/firestore";

import { FirebaseContext } from "../../../App";

export const User = () => {
  const { filter } = useContext(FilterContext);
  const db = useContext(FirebaseContext);
  const [userState, setUserState] = useState<any>();
  const func = async () => {
    const user = await getDoc(doc(db, "users", filter.currentUser));

    setUserState(user.data());
  };
  useEffect(() => {
    if (filter.currentUser) {
      func();
    }
  }, [filter]);
  return (
    <>
      {filter.user === FilterUserEnum.USER && (
        <div
          style={{
            marginTop: "6px",
            marginLeft: "12px",
            fontFamily: "Lato-700",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          ( {userState && userState.name} )
        </div>
      )}
    </>
  );
};
