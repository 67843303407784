import { Col, Row } from "antd";

import { memo, useCallback, useContext, useMemo } from "react";

import { FilterContext } from "..";
import classes from "../styles/dashboard.module.scss";
import { UserCategoryChart } from "./userCharts/UserCategoryChart";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsersAnalytics,
  setActivityAnalyticsData,
  setMoodAnalyticsData,
  setNutritionAnalyticsData,
} from "../../../servises/store/slice/userAnalyticsSlice";
import { ACTIVITY, MOOD, NUTRITION } from "../../../data/categories";
import { Diagram } from "./diagrams";
import {
  selectCompanyAnalytics,
  setCompanyMood,
  setCompanyNutrition,
  setCompanyActivity,
} from "../../../servises/store/slice/companyAnalyticsSlice";
import { getMeasureSystem } from "../../../utils";
import { RootState } from "../../../servises/store";

export const SecondDashboard = () => {
  const dispatch = useDispatch();
  const { moodAnalyticsData, activityAnalyticsData } =
    useSelector(selectUsersAnalytics);

  const nutritionAnalyticsData = useSelector(
    (state: RootState) => state.usersAnalytics.nutritionAnalyticsData
  );

  const { diagrams } = useSelector(selectCompanyAnalytics);
  const { filter } = useContext(FilterContext);

  const onChangeMood = useCallback((category: string) => {
    dispatch(setMoodAnalyticsData(category));
  }, []);

  const onChangeActivity = useCallback((category: string) => {
    dispatch(setActivityAnalyticsData(category));
  }, []);

  const onChangeNutrition = useCallback((category: string) => {
    dispatch(setNutritionAnalyticsData(category));
  }, []);

  const onChangeCompanyMood = (category: string) => {
    dispatch(setCompanyMood({ category, gender: filter.user }));
  };

  const onChangeCompanyActivity = (category: string) => {
    dispatch(setCompanyActivity({ category, gender: filter.user }));
  };

  const onChangeCompanyNutrition = (category: string) => {
    dispatch(setCompanyNutrition({ category, gender: filter.user }));
  };

  const NutritionMemoized = useMemo(() => {
    return NUTRITION(getMeasureSystem());
  }, []);

  return (
    <div className={classes.secondDashboard}>
      <Row align="stretch" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className={classes.dashboard_column} span={8}>
          {filter.user === "user" ? (
            <UserCategoryChart
              title="Mood"
              chartData={moodAnalyticsData}
              categories={MOOD}
              onChangeCategory={onChangeMood}
              tooltipText="Data on self-assessment of the user's emotional state for a selected period of time in various areas of life. Gradation from 1 to 5, where 1 is very bad, 2 is bad, 3 is normal, 4 is good, and 5 is excellent"
            />
          ) : (
            <Diagram
              title="Mood"
              onChangeCategory={onChangeCompanyMood}
              categories={MOOD}
              diagrams={diagrams.mood}
              tooltipText="Proportion of users who rated their emotional state as excellent, good, normal, bad, or very bad in different areas of life"
            />
          )}
        </Col>
        <Col className={classes.dashboard_column} span={8}>
          {filter.user === "user" ? (
            <UserCategoryChart
              title="Activity"
              chartData={activityAnalyticsData}
              categories={ACTIVITY}
              onChangeCategory={onChangeActivity}
              tooltipText="User data on various activity indicators for a selected period of time"
            />
          ) : (
            <Diagram
              title="Activity"
              onChangeCategory={onChangeCompanyActivity}
              categories={ACTIVITY}
              diagrams={diagrams.activity}
              tooltipText="Proportion of users who entered data on their activity depending on the deviation from the norms"
            />
          )}
        </Col>
        <Col className={classes.dashboard_column} span={8}>
          {filter.user === "user" ? (
            <UserCategoryChart
              title="Nutrition"
              chartData={nutritionAnalyticsData}
              categories={NutritionMemoized}
              onChangeCategory={onChangeNutrition}
              tooltipText="User data on nutrition and water consumption for a selected period of time"
            />
          ) : (
            <Diagram
              title="Nutrition"
              onChangeCategory={onChangeCompanyNutrition}
              categories={NutritionMemoized}
              diagrams={diagrams.nutrition}
              tooltipText="Proportion of users who entered data on their nutrition depending on the deviation from the norms"
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
