import { memo, useContext } from "react";
import { FilterContext, FilterUserEnum } from "../..";
import { Card } from "../../../../assets/components/display/Card";
import classes from "../../styles/dashboard.module.scss";
import { NoData } from "../../../../assets/components/display/NoData";
import { useSelector } from "react-redux";
import { selectSubscriptionSlice } from "../../../../servises/store/slice/subscriptionSlice";
import { UserMainChart } from "./UserChart";
import { CompanyMainChart } from "./CompanyChart";

export const MainChart = () => {
  const { filter } = useContext(FilterContext);
  const { subscription } = useSelector(selectSubscriptionSlice);
  return (
    <Card>
      <div className={classes.card}>
        {!subscription ? (
          <NoData />
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            {filter.currentUser && filter.user === FilterUserEnum.USER ? (
              <UserMainChart />
            ) : (
              <CompanyMainChart />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

