import { ReactNode } from "react";
import { Card } from "../../../assets/components/display/Card";
import classes from "../styles/auth.module.scss";
import auth from "../../../assets/images/auth/auth.png";
import icon from "../../../assets/images/auth/icon.png";

interface IAuthWrappedCardComponentProps {
  children: ReactNode;
  title: string;
}

export const AuthWrappedCardComponent = ({
  children,
  title,
}: IAuthWrappedCardComponentProps) => {
  return (
    <div className={classes.auth__body}>
      <Card>
        <div className={classes.auth__card_content}>
          <img src={auth} alt="auth" />
          <div className={classes.auth__registration_form}>
            <p className={classes.auth__title}>
              <img src={icon} alt="icon" />
              {title}
            </p>
            {children}
          </div>
        </div>
      </Card>
    </div>
  );
};
