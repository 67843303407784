import { memo, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ColumnChart } from "../../../../assets/components/data/ColumnChart";
import { Card } from "../../../../assets/components/display/Card";

import { IColumnProps } from "../../../../assets/components/data/Column";
import classes from "./styles.module.scss";
import { Button, Tooltip } from "antd";
import { RootState } from "../../../../servises/store";
import { IDiagramSlice } from "../../../../type/company";
import { FilterContext } from "../..";
import { HelpCircleIcon } from "../../../../assets/images/auth/svg";
import { NoData } from "../../../../assets/components/display/NoData";
import { selectSubscriptionSlice } from "../../../../servises/store/slice/subscriptionSlice";

interface IDiagramProps {
  onChangeCategory: (category: string) => void;
  title: string;
  diagrams: IDiagramSlice[];
  categories: {
    title: string;
    value: string;
  }[];
  tooltipText: string;
}
const DiagramComponent = ({
  onChangeCategory,
  title,
  diagrams,
  categories,
  tooltipText,
}: IDiagramProps) => {
  const { subscription } = useSelector(selectSubscriptionSlice);
  const { filter } = useContext(FilterContext);
  const allUsers = useSelector(
    (state: RootState) => state.companyAnalytics.allUsersAnalyticsData
  );
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  const changeCategoryHandler = (categoryValue: string) => {
    const activeCategoryToFind = categories.find(
      (category) => category.value === categoryValue
    );

    if (activeCategoryToFind) {
      setActiveCategory(activeCategoryToFind);
    }
    onChangeCategory(categoryValue);
  };

  useEffect(() => {
    onChangeCategory(activeCategory.value);
  }, [allUsers, filter.user]);

  const data = diagrams.map((item) => {
    return {
      subTitle: item.title,
      percents: Math.round(item.count),
    } as IColumnProps;
  });
  return (
    <Card fullHeight>
      <div className={classes.cardContent}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3>{title}</h3>
            <div className={classes.tooltip}>
              <Tooltip title={tooltipText}>
                <Button>
                  <HelpCircleIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className={classes.userCategoriesTabs}>
            {categories.map((category) => (
              <div key={category.value} className={classes.item}>
                <Button
                  className={
                    activeCategory.value === category.value
                      ? `${classes.active}`
                      : `${classes.button}`
                  }
                  onClick={() => changeCategoryHandler(category.value)}
                >
                  {category.title}
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.chart}>
          {subscription ?
            <ColumnChart data={data} /> : <NoData />
          }
        </div>
      </div>
    </Card>
  );
};

export const Diagram = memo(DiagramComponent);
