import {
  query,
  collection,
  doc,
  getDocs,
  where,
  getDoc,
} from "@firebase/firestore";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFilter } from "../../../routes/dashboard";
import { IUserAnalytics } from "../../../type/user_analytics";
import { getDb, dateToLocalDateString } from "../../../utils";
import { RootState } from "..";
import { IDiagramSlice } from "../../../type/company";
import dayjs from "dayjs";

const DEFAULT_MOOD_DATA: IDiagramSlice[] = [
  {
    title: "Very bad",
    count: 0,
    limit: [1, 1.8],
  },
  {
    title: "Bad",
    count: 0,
    limit: [1.8, 2.6],
  },
  {
    title: "Normal",
    count: 0,
    limit: [2.6, 3.4],
  },
  {
    title: "Good",
    count: 0,
    limit: [3.4, 4.2],
  },
  {
    title: "Excellent",
    count: 0,
    limit: [4.2, 5.1],
  },
];

const DEFAULT_ACTIVITY_NUTRITION_DATA: IDiagramSlice[] = [
  {
    title: "<50%",
    count: 0,
    limit: [0, 0.5],
  },
  {
    title: "50-90%",
    count: 0,
    limit: [0.5, 0.9],
  },
  {
    title: "90-100%",
    count: 0,
    limit: [0.9, 1.1],
  },
  {
    title: "110-150%",
    count: 0,
    limit: [1.1, 1.5],
  },
  {
    title: ">150%",
    count: 0,
    limit: [1.5, Infinity],
  },
];

export const getAllUsersAnalyticsThunk = createAsyncThunk(
  "getAllUsersAnalytics",
  async ({ filter }: { filter: IFilter }) => {
    const companyRefId = localStorage.getItem("company_ref_id") || "";

    const db = getDb();
    const { period, age } = filter;
    let data: IUserAnalytics[] = [];
    try {
      let dateRange = period;
      if (period[0].$d && period[1].$d) {
        dateRange = [dayjs(period[0]).subtract(1, "days").toDate(), period[1].$d];
      }

      const q = query(
        collection(db, "users_analytics"),
        where(
          "company_refs",
          "array-contains",
          doc(db, "companies", companyRefId)
        ),
        where("report_date", ">", dateRange[0]),
        where("report_date", "<", dateRange[1])
      );

      const userDocs = await getDocs(q);

      userDocs.forEach(async (item) => {
        const date = dateToLocalDateString(item.data().report_date, "en-EN");
        const dataToArray = {
          date,
          id: item.data().user_id,
          age: item.data().age,
          gender: item.data().gender,
          is_active_today: item.data().is_active_today,
          values: item.data().values,
        };
        if (item.data().age >= age[0] && item.data().age <= age[1]) {
          data.push(dataToArray);
        }
      });

      const getUserAnalyticsData = async (
        userId: string
      ): Promise<IUserAnalytics[]> => {
        let dateRange = period;
        if (period[0].$d && period[1].$d) {
          dateRange = [period[0].$d, period[1].$d];
        }
        const usersAnalyticsCollection = query(
          collection(db, "users_analytics"),
          where("user_ref", "==", doc(db, "users", userId)),
          where("report_date", ">", dateRange[0]),
          where("report_date", "<", dateRange[1])
        );
        let userAnalytics: IUserAnalytics[] = [];
        const usersAnalyticsDocs = await getDocs(usersAnalyticsCollection);
        usersAnalyticsDocs.forEach((item) => {
          if (item.data().values) {
            const date = dateToLocalDateString(
              item.data().report_date,
              "en-EN"
            );
            const dataToArray = {
              date,
              id: userId,
              age: item.data().age,
              gender: item.data().gender,
              is_active_today: item.data().is_active_today,
              values: item.data().values,
            };
            if (item.data().age >= age[0] && item.data().age <= age[1]) {
              userAnalytics.push(dataToArray);
            }
          }
        });
        return userAnalytics;
      };
      const getAllUsersAnalyticsData = async () => {
        const companies = query(
          collection(db, "company_users"),
          where("company_ref", "==", doc(db, "companies", companyRefId))
        );
        const usersDocs = await getDocs(companies);
        let resolve: (
          value: IUserAnalytics[][] | PromiseLike<IUserAnalytics[][]>
        ) => void;
        let analytics: IUserAnalytics[][] = [];
        const promise: Promise<IUserAnalytics[][]> = new Promise((res, rej) => {
          resolve = res;
        });

        usersDocs.forEach(async (userItem) => {
          const docUserSnap = await getDoc(userItem.data().user_ref);
          const userAnalytics = await getUserAnalyticsData(docUserSnap.id);
          analytics = [...analytics, userAnalytics];
          setTimeout(() => {
            resolve(analytics);
          });
        });
        return promise;
      };
      // data = (await getAllUsersAnalyticsData()).flat(1);
    } catch (error) {
      console.log(error);
    }
    return { data, filter };
  }
);

const filterByAge = (data: IUserAnalytics[], age: number[]) => {
  return data.filter((item) => item.age >= age[0] && item.age <= age[1]);
};

const getAnalyticsData = (data: IUserAnalytics[], gender: string) => {
  const uniqueDates = Array.from(new Set(data.map((item) => item.date)));
  const groupedByDates = uniqueDates.map((date) => {
    return {
      date,
      values: data.filter((item) => {
        if (gender === "all") {
          return item.date === date;
        } else {
          return item.date === date && item.gender === gender;
        }
      }),
    };
  });
  const analyticsData = groupedByDates.map((date) => {
    return {
      date: date.date,
      value: date.values.reduce(
        (acc, curr) => (curr.is_active_today ? acc + 1 : acc),
        0
      ),
    };
  });

  const calculateAvg = (value: string) => {
    return (
      groupedByDates.reduce((totalIndex, currentDate) => {
        const usersTotalIndex = currentDate.values.reduce(
          (acc, curr) => {
            return {
              total: curr.values[value as keyof typeof curr.values]
                ? acc.total +
                  (curr.values[value as keyof typeof curr.values] as number)
                : acc.total,
              count: curr.values[value as keyof typeof curr.values]
                ? acc.count + 1
                : acc.count,
            };
          },
          {
            total: 0,
            count: 0,
          }
        );
        const usersIndexCalculated =
          usersTotalIndex.total / usersTotalIndex.count;

        return usersIndexCalculated
          ? totalIndex + usersIndexCalculated
          : totalIndex;
      }, 0) / uniqueDates.length
    );
  };

  const scoresIndexes = {
    avg_health_score: calculateAvg("avg_health_score"),
    avg_health_index: calculateAvg("avg_health_index"),
  };
  return { analyticsData, scoresIndexes };
};

const getMoodData = (
  data: IUserAnalytics[],
  category: string,
  gender: string
): IDiagramSlice[] => {
  const uniqueIds = Array.from(new Set(data.map((item) => item.id)));

  const groubedByIds = uniqueIds.map((id) => {
    return {
      id,
      values: data.filter((item) => {
        if (gender === "all") {
          return item.id === id;
        } else {
          return item.id === id && item.gender === gender;
        }
      }),
    };
  });

  const usersMoodCalculated = groubedByIds.map((item) => {
    return {
      id: item.id,
      value: item.values.reduce(
        (acc, curr) => {
          const moodCategory =
            curr.values.mood[category as keyof typeof curr.values.mood];

          return {
            total: moodCategory ? acc.total + moodCategory : acc.total,
            count: moodCategory ? acc.count + 1 : acc.count,
          };
        },
        { total: 0, count: 0 }
      ),
    };
  });

  const notEmptyUsersCount = usersMoodCalculated.reduce((acc, curr) => {
    return curr.value.count ? acc + 1 : acc;
  }, 0);

  return DEFAULT_MOOD_DATA.map((item) => {
    const count = usersMoodCalculated.reduce((acc, curr) => {
      const countedWithTotal = curr.value.total / curr.value.count;
      if (!countedWithTotal) {
        return acc;
      }

      return countedWithTotal >= item.limit[0] &&
        countedWithTotal < item.limit[1]
        ? acc + 1
        : acc;
    }, 0);
    const percentage = (count * 100) / notEmptyUsersCount;
    return {
      ...item,
      count: percentage,
    };
  });
};

const getNutritionOrActivityData = (
  data: any[],
  gender: string,
  category: string,
  valueType: string
) => {
  const uniqueIds = Array.from(new Set(data.map((item) => item.id)));

  const groupedByIds = uniqueIds.map((id) => {
    return {
      id,
      values: data.filter((item) => {
        if (gender === "all") {
          return item.id === id;
        } else {
          return item.id === id && item.gender === gender;
        }
      }),
    };
  });

  const usersValuesCalculated = groupedByIds.map((user) => {
    return {
      id: user.id,
      value: user.values.reduce(
        (acc, curr) => {
          const valueCategory = curr.values[valueType][category];
          const valueCategoryNorm = curr.values[valueType][`${category}_norm`];
          return {
            total: valueCategory
              ? acc.total + valueCategory / valueCategoryNorm
              : acc.total,
            count: valueCategory ? acc.count + 1 : acc.count,
          };
        },
        { total: 0, count: 0 }
      ),
    };
  });

  const notEmptyUsersCount = usersValuesCalculated.reduce((acc, curr) => {
    return curr.value.count ? acc + 1 : acc;
  }, 0);

  return DEFAULT_ACTIVITY_NUTRITION_DATA.map((item) => {
    const count = usersValuesCalculated.reduce((acc, curr) => {
      const countedWithTotal = curr.value.total / curr.value.count;
      if (!countedWithTotal) {
        return acc;
      }
      return countedWithTotal >= item.limit[0] &&
        countedWithTotal <= item.limit[1]
        ? acc + 1
        : acc;
    }, 0);

    const percentage = (count * 100) / notEmptyUsersCount;

    return {
      ...item,
      count: percentage,
    };
  });
};

interface IAnalyticsData {
  value: number | null;
  date: string;
}

interface IAllUserAnalyticsSlice {
  allUsersAnalyticsData: IUserAnalytics[];
  activity: IAnalyticsData[];
  healthscores: {
    avg_health_score: number;
    avg_health_index: number;
  };
  diagrams: {
    mood: IDiagramSlice[];
    activity: IDiagramSlice[];
    nutrition: IDiagramSlice[];
  };
  loading: boolean;
}

const initialState: IAllUserAnalyticsSlice = {
  allUsersAnalyticsData: [],
  healthscores: {
    avg_health_score: 0,
    avg_health_index: 0,
  },
  activity: [],
  diagrams: {
    mood: DEFAULT_MOOD_DATA,
    activity: DEFAULT_ACTIVITY_NUTRITION_DATA,
    nutrition: DEFAULT_ACTIVITY_NUTRITION_DATA,
  },
  loading: false,
};

const allUserAnalyticsSlice = createSlice({
  name: "allUserAnalyticsSlice",
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    setActivity(state, { payload }: PayloadAction<string>) {
      const { analyticsData, scoresIndexes } = getAnalyticsData(
        state.allUsersAnalyticsData,
        payload
      );
      state.activity = analyticsData;
      state.healthscores = scoresIndexes;
    },
    setFilteredByAget(state, { payload }: PayloadAction<number[]>) {
      state.allUsersAnalyticsData = filterByAge(
        state.allUsersAnalyticsData,
        payload
      );
    },
    setCompanyMood(
      state,
      { payload }: PayloadAction<{ category: string; gender: string }>
    ) {
      state.diagrams.mood = getMoodData(
        state.allUsersAnalyticsData,
        payload.category,
        payload.gender
      );
    },
    setCompanyActivity(
      state,
      { payload }: PayloadAction<{ category: string; gender: string }>
    ) {
      state.diagrams.activity = getNutritionOrActivityData(
        state.allUsersAnalyticsData,
        payload.gender,
        payload.category,
        "activity"
      );
    },
    setCompanyNutrition(
      state,
      { payload }: PayloadAction<{ category: string; gender: string }>
    ) {
      state.diagrams.nutrition = getNutritionOrActivityData(
        state.allUsersAnalyticsData,
        payload.gender,
        payload.category,
        "food"
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersAnalyticsThunk.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.allUsersAnalyticsData = payload.data;
          const { analyticsData, scoresIndexes } = getAnalyticsData(
            payload.data,
            payload.filter.user
          );
          state.activity = analyticsData;
          state.healthscores = scoresIndexes;
        }
        state.loading = false;
      })
      .addCase(getAllUsersAnalyticsThunk.pending, (state) => {
        state.loading = true;
      });
  },
});

export const {
  reset,
  setActivity,
  setCompanyActivity,
  setCompanyMood,
  setCompanyNutrition,
} = allUserAnalyticsSlice.actions;
export const selectCompanyAnalytics = (state: RootState) =>
  state.companyAnalytics;

export default allUserAnalyticsSlice.reducer;
