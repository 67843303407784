import { useState } from "react";
import { BaseInput } from "../../assets/components/input/BaseInput";
import { AuthWrappedCardComponent } from "../auth/components/AuthWrappedCardComponent";
import { Spin } from "antd";
import classes from "./styles.module.scss";
import { BasePrimaryButton } from "../../assets/components/input/BaseButton";
import { useFormik } from "formik";
import { object, ref, string } from "yup";
import { confirmPasswordReset, getAuth } from "firebase/auth";
import { locals } from "../../servises/locals";
import { AuthErrorEnum } from "../../type/auth";
import { errorHandler } from "../../utils";
import { useNavigate } from "react-router-dom";

export const ResetConfirimPassword = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: object({
      new_password: string().required(),
      confirm_password: string()
        .required()
        .oneOf([ref("new_password")], locals.auth.passwordMissmatch),
    }),

    onSubmit: async ({ new_password }) => {
      setLoading(true);
      const oobCode = localStorage.getItem("oobCode");
      const auth = getAuth();

      try {
        if (oobCode) {
          await confirmPasswordReset(auth, oobCode, new_password);
          setLoading(false);
          navigate("/login");
          localStorage.removeItem("oobCode");
        } else {
          setMessage("Something went wrong. Try again later.");
        }
      } catch (error: any) {
        setMessage(errorHandler(error.code as AuthErrorEnum));
        setLoading(false);
      }
    },
  });

  return (
    <AuthWrappedCardComponent title="Password reset">
      <form onSubmit={formik.handleSubmit} className={classes.input}>
        <BaseInput
          label="New password"
          name="new_password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.new_password}
          valid={!(formik.errors.new_password && formik.touched.new_password)}
        />
        <BaseInput
          label="Confirm password"
          name="confirm_password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.confirm_password}
          valid={
            !(formik.errors.confirm_password && formik.touched.confirm_password)
          }
        />
        <p>{message}</p>
        <div className={classes.controls}>
          <BasePrimaryButton disabled={loading}>
            {loading ? <Spin /> : "Change"}
          </BasePrimaryButton>
        </div>
      </form>
    </AuthWrappedCardComponent>
  );
};
