import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import classes from "../styles/payCondition.module.scss";

export enum PayConditionStatusEnum {
  DISABLED = "disabled",
  ACTIVE = "active",
  UNABLE = "unable",
}

interface IPayConditionSectionProps {
  status: PayConditionStatusEnum;
  title: string;
  value?: number;
}

export const PayConditionSection = ({
  status,
  title,
  value,
}: IPayConditionSectionProps) => {
  return (
    <span className={classes.pay_condition_section__container}>
      {status === PayConditionStatusEnum.UNABLE ? (
        <CheckCircleOutlined
          className={classes.pay_condition_section__icon_unable}
        />
      ) : (
        <CheckCircleFilled
          className={classes.pay_condition_section__icon_active}
        />
      )}

      <span
        style={
          status === PayConditionStatusEnum.UNABLE ? { color: "#818B8B" } : {}
        }
      >
        {title}
      </span>
      {value && value}
      <Divider style={{ margin: "14px 0" }} />
    </span>
  );
};
