import { ReactNode } from "react";

interface ITitleProps {
  children: ReactNode;
}

export const Title = ({ children }: ITitleProps) => {
  return (
    <div
      style={{
        fontFamily: "Lato-700",
        fontSize: "34px",
        lineHeight: "41px",
      }}
    >
      {children}
    </div>
  );
};
