import { Column, IColumnProps } from "./Column";

import classes from "./styles/columnChart.module.scss";

export interface IColumnChartProps {
  data: IColumnProps[];
}

export const ColumnChart = ({ data }: IColumnChartProps) => {
  return (
    <div className={classes.column_chart__body}>
      {data.map((column, index) => (
        <Column
          percents={column.percents}
          key={column.subTitle + column.percents + index}
          subTitle={column.subTitle}
        />
      ))}
    </div>
  );
};
