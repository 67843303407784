import { doc, getDoc } from "@firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../App";
import { FilterContext } from "../routes/dashboard";

export const useGetUser = () => {
  const db = useContext(FirebaseContext);
  const { filter } = useContext(FilterContext);
  const [userState, setUserState] = useState<any>();
  const func = async () => {
    const user = await getDoc(doc(db, "users", filter.currentUser));
    setUserState(user.data());
  };
  useEffect(() => {
    if (filter.currentUser) {
      func();
    }
  }, [filter.currentUser]);

  return { userState };
};
