import "./App.css";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { router } from "./routes";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { createContext } from "react";
import "firebase/firestore";
import { getAuth } from "firebase/auth";
import { createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import store from "./servises/store";
import "firebase/firestore";
import { FirebaseAppProvider } from "reactfire";
import { getPerformance } from "firebase/performance";

const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG;

const app = initializeApp(JSON.parse(firebaseConfig as string));
const perf = getPerformance(app);
const auth = getAuth();
const db = getFirestore(app);

export const rrfConfig = {
  userProfile: "users",

  useFirestoreForProfile: true,
  enableRedirectHandling: false,
  resetBeforeLogin: false,
};

export const rrfProps = {
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
  userProfile: "users",
  attachAuthIsReady: true,
  firebase: app,
};

export const FirebaseContext = createContext(db);
export const AuthContext = createContext(auth);

export const App = () => {
  return (
      <ReactReduxFirebaseProvider {...rrfProps}>
        <FirebaseAppProvider firebaseApp={app}>
          <AuthContext.Provider value={auth}>
            <FirebaseContext.Provider value={db}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#2b42a8",
                    colorLink: "#101012",
                    colorLinkHover: "#b8bac3",
                    colorLinkActive: "#b8bac3",
                  },
                }}
              >
                <div className="App">
                  <RouterProvider router={router} />
                </div>
              </ConfigProvider>
            </FirebaseContext.Provider>
          </AuthContext.Provider>
        </FirebaseAppProvider>
      </ReactReduxFirebaseProvider>
  );
};
