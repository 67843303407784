import { Button } from "antd";
import { StarFilled } from "@ant-design/icons";
import classes from "./styles/paystatus.module.scss";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectSubscriptionSlice } from "../../../servises/store/slice/subscriptionSlice";

export const PayStatus = () => {
  const navigate = useNavigate();
  const { paystatus, subscription } = useSelector(selectSubscriptionSlice);
  let daysToExpire = 0;

  if (subscription) {
    const todayDate = new Date();
    const expireDate = new Date(
      subscription.next_payment_at._seconds * 1000 +
      subscription.next_payment_at._nanoseconds / 1000000
    );

    daysToExpire = Math.ceil((expireDate.getTime() - todayDate.getTime()) / 86400000);
  }

  const navigateToPayboardHandler = () => {
    navigate("/payboard");
  };

  return (
    <div
      className={paystatus ? `${classes.paystatus_body} ` : `${classes.paystatus_body} ${classes.paystatus_body__disabled}`}
    >
      <StarFilled />
      <span className={classes.paystatus_title}>{paystatus}</span>
      <span className={classes.paystatus_expiers}>
        {paystatus ? `expires in ${daysToExpire} days` : 'Select a tariff'}
      </span>
      <Button type="default" onClick={navigateToPayboardHandler}>
        {paystatus ? "Renew" : "Start"}
      </Button>
    </div>
  );
};
