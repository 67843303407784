import { applyActionCode, getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [searchParmas] = useSearchParams();
  const actionCode = searchParmas.get("oobCode");
  const mode = searchParmas.get("mode");
  useEffect(() => {
    if (mode === "resetPassword" && actionCode) {
      localStorage.setItem("oobCode", actionCode);
      navigate("/confirm-reset");
      return;
    }
    const verifyEmail = async () => {
      const auth = getAuth();
      if (actionCode) {
        try {
          applyActionCode(auth, actionCode);
          navigate("/login");
        } catch (error) {
          navigate("/auth");
        }
      } else {
        navigate("/auth");
      }
    };
    verifyEmail();
  }, []);

  return <div>Confirmiation...</div>;
};
