import { getAuth } from "@firebase/auth";

import { useEffect, useState } from "react";

import { BaseLayout } from "../../assets/components/display/Layout";
import Card from "antd/es/card/Card";
import { Header } from "../../assets/components/display/Header";
import { SettingsInput } from "./SettingsInput";
import classes from "./Settings.module.scss";
import { locals } from "../../servises/locals";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../servises/store";
import {
  getCompaniesThunk,
  selectSubscriptionSlice,
} from "../../servises/store/slice/subscriptionSlice";
import { Select } from "antd";
import {
  getMeasureSystem,
  setMeasureSystem as setMeasureSystemLS,
} from "../../utils";

export const Settings = () => {
  const measureSystem = getMeasureSystem();
  const dispatch = useDispatch<AppDispatch>();
  const auth = getAuth().currentUser;
  const { company } = useSelector(selectSubscriptionSlice);

  const changeMeeasureSystemHandler = (value: string) => {
    setMeasureSystemLS(value);
  };

  useEffect(() => {
    dispatch(getCompaniesThunk());
  }, []);

  const submitEmailHandler = () => {
    dispatch(getCompaniesThunk());
  };

  return (
    <BaseLayout>
      <Header title={locals.title.settings} />
      <div className={classes.card}>
        <Card>
          <SettingsInput
            onSubmit={submitEmailHandler}
            name="Name"
            value={company.name}
            type="text"
          />
          <SettingsInput
            onSubmit={() => {}}
            name="Nickname"
            value={company.nickname.substring(
              company.nickname.length - 4,
              company.nickname.length
            )}
            type="text"
            editable={false}
          />
          <SettingsInput
            onSubmit={submitEmailHandler}
            name="Email"
            value={auth?.email || ""}
            type="email"
          />
          <SettingsInput onSubmit={() => {}} name="Password" type="password" />
          <p>Measure system</p>
          <Select
            size="large"
            className={classes.measureSystem}
            defaultValue={measureSystem}
            onChange={changeMeeasureSystemHandler}
            options={[
              { value: "EU", label: "Metric" },
              { value: "US", label: "Imperial" },
            ]}
          />
        </Card>
      </div>
    </BaseLayout>
  );
};
