import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  AuthError,
  applyActionCode,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { object, ref, string } from "yup";

import { AuthWrappedCardComponent } from "./components/AuthWrappedCardComponent";
import { BaseInput } from "../../assets/components/input/BaseInput";
import { BasePrimaryButton } from "../../assets/components/input/BaseButton";
import { FirebaseContext } from "../../App";
import { LinkButton } from "../../assets/components/input/LinkButton";
import classes from "./styles/form.module.scss";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { locals } from "../../servises/locals";

export const Auth = () => {
  const db = useContext(FirebaseContext);
  const auth = getAuth();
  const navigate = useNavigate();
  
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      company_name: "",
      email: "",
      password: "",
      verify_password: "",
    },
    validationSchema: object({
      company_name: string().required(),
      email: string().email().required(),
      verify_password: string()
        .required()
        .oneOf([ref("password")], locals.auth.passwordMissmatch),
      password: string().required(),
    }),

    onSubmit: async ({ company_name, email, password }) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        const user = userCredential.user;
        const res = await sendEmailVerification(auth.currentUser!);

        await addDoc(collection(db, "companies"), {
          name: company_name,
          admin_user_uid: user.uid,
          nickname:
            company_name.replaceAll(" ", "-") +
            "-" +
            Math.floor(Math.random() * (9999 - 1000) + 1000),
        });

        const initAuth = getAuth();
        if (initAuth.currentUser) {
          const userId = initAuth.currentUser?.uid;
          localStorage.setItem("user_uid", userId);
          const companyQuery = query(
            collection(db, "/companies"),
            where("admin_user_uid", "==", userId)
          );
          const companyDocs = await getDocs(companyQuery);
          companyDocs.forEach((item) => {
            localStorage.setItem("company_ref_id", item.id);
          });
          navigate("/login");
        }
      } catch (error: any) {
        if (error.code === "auth/email-already-in-use") {
          setMessage("Email alreay in use");
        }
      }
    },
  });

  return (
    <AuthWrappedCardComponent title="Sign Up">
      <form onSubmit={formik.handleSubmit} className={classes.children_form}>
        <BaseInput
          name="company_name"
          disabled={false}
          label="Company name"
          type="text"
          valid={!(formik.errors.company_name && formik.touched.company_name)}
          value={formik.values.company_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <BaseInput
          name="email"
          disabled={false}
          label="E-mail"
          type="text"
          valid={!(formik.errors.email && formik.touched.email)}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <BaseInput
          name="password"
          disabled={false}
          label="Password"
          type="password"
          valid={
            !(
              formik.errors.password &&
              formik.touched.password &&
              !(formik.values.verify_password === formik.values.password)
            )
          }
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <BaseInput
          name="verify_password"
          disabled={false}
          label="Verify password:"
          type="password"
          valid={
            !(formik.errors.verify_password && formik.touched.verify_password)
          }
          value={formik.values.verify_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <p className={classes.errorMessage}>{message}</p>
        <BasePrimaryButton> Sign Up </BasePrimaryButton>
        <LinkButton name="Log In" href="/login" />
      </form>
    </AuthWrappedCardComponent>
  );
};
