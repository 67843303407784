import { initializeApp } from "@firebase/app";
import { Timestamp, getFirestore } from "@firebase/firestore";
import { AuthErrorEnum } from "../type/auth";
import { IMPERIC_KOEFS, measureSystemLS } from "../const";

interface ITimestamp {
  nanoseconds: number;
  seconds: number;
}

export const timestampToDate = ({ nanoseconds, seconds }: ITimestamp): Date => {
  return new Date(seconds * 1000 + nanoseconds / 1000000);
};

export const isDateInRange = (date: Date, range: Date[]): boolean => {
  return (
    date.getTime() >= range[0].getTime() && date.getTime() <= range[1].getTime()
  );
};

export const getDb = () => {
  const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG;
  const app = initializeApp(JSON.parse(firebaseConfig as string));
  return getFirestore(app);
};

export const toExcelDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-En", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const dateToLocalDateString = (
  { nanoseconds, seconds }: ITimestamp,
  locale: string
) => {
  return timestampToDate({ nanoseconds, seconds }).toLocaleDateString(locale, {
    day: "2-digit",
    month: "short",
  });
};

export const firstLetterToUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const errorHandler = (errorCode: AuthErrorEnum): string => {
  switch (errorCode) {
    case AuthErrorEnum.EMAIL_EXISTS:
      return "Email already in use";
    case AuthErrorEnum.WRONG_PASSWORD:
      return "Wrong password";
    default:
      return "Something went wrong :(";
  }
};

export const getDate = (date: any, nano: any) => {
  let currentDate = new Timestamp(date, nano);
  return currentDate.toDate().toLocaleDateString("en-En", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const setMeasureSystem = (value: string) => {
  localStorage.setItem(measureSystemLS, value);
};

export const getMeasureSystem = () => {
  return localStorage.getItem(measureSystemLS) || "EU";
};

export const applyMeasureSystem = (
  data: { date: string; value: number | null }[],
  measurement: string,
  isCategoryActive: boolean
) => {
  const measureSystem = getMeasureSystem();
  if (measureSystem === "US" && isCategoryActive) {
    return data.map((item) => {
      return {
        ...item,
        value:
          item.value === null
            ? null
            : item.value *
              IMPERIC_KOEFS[measurement as keyof typeof IMPERIC_KOEFS],
      };
    });
  }
  return data;
};
