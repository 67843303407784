import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dateToLocalDateString, getDb } from "../../../utils";
import { FilterUserEnum, IFilter } from "../../../routes/dashboard";
import { collection, doc, getDocs, query, where } from "@firebase/firestore";
import { RootState } from "..";

export const flattenObject = (obj: any) => {
  const flattened: any = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

const applyHealthScores = (data: any[], userFilter: string) => {
  if (!data.length) {
    return {
      avg_health_index: 0,
      avg_health_score: 0,
    };
  }
  return {
    avg_health_index:
      data[data.length - 1].values[`${userFilter}_avg_health_index`],
    avg_health_score:
      data[data.length - 1].values[`${userFilter}_avg_health_score`],
  };
};

const applyAnalyticsData = (data: any[], userFilter: string) => {
  return data.map((item) => {
    return {
      date: item.date,
      value: item.values[`${userFilter}_active_users_count`],
    };
  });
};

export const getCompanyAnalyticsThunk = createAsyncThunk(
  "getCompanyAnalytics",
  async (filter: IFilter) => {
    const db = getDb();
    const companyRefId = localStorage.getItem("company_ref_id") || "";
    const { period } = filter;

    let dateRange = period;
    if (period[0].$d && period[1].$d) {
      dateRange = [period[0].$d, period[1].$d];
    }

    let data: any[] = [];
    try {
      const companies = query(
        collection(db, "companies_analytics"),
        where("company_ref", "==", doc(db, "companies", companyRefId)),
        where("report_date", ">", dateRange[0]),
        where("report_date", "<", dateRange[1])
      );

      const docs = await getDocs(companies);

      docs.forEach((item) => {
        if (item.data().values) {
          const date = dateToLocalDateString(item.data().report_date, "en-EN");
          data.push({ date, values: item.data().values });
        }
      });
    } catch (error) {
      console.log(error);
    }

    return { data, filter };
  }
);

interface IMainCompanyChartSlice {
  companyAnalytics: any[];
  analyticsData: any[];
  loading: boolean;
  healthScores: {
    avg_health_index: number;
    avg_health_score: number;
  };
}

const initialState: IMainCompanyChartSlice = {
  companyAnalytics: [],
  analyticsData: [],
  loading: false,
  healthScores: {
    avg_health_index: 0,
    avg_health_score: 0,
  },
};

const mainCompanyChartSlice = createSlice({
  name: "mainCompanyChartSlice",
  initialState,
  reducers: {
    setActivity(state, { payload }: PayloadAction<IFilter>) {
      const { user } = payload;
      const userFilter = user === FilterUserEnum.ALL ? "common" : user;

      state.analyticsData = applyAnalyticsData(
        state.companyAnalytics,
        userFilter
      );

      state.healthScores = applyHealthScores(
        state.companyAnalytics,
        userFilter
      );
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyAnalyticsThunk.fulfilled, (state, { payload }) => {
        state.companyAnalytics = payload.data;
        const { user } = payload.filter;
        const userFilter = user === FilterUserEnum.ALL ? "common" : user;

        state.analyticsData = applyAnalyticsData(payload.data, userFilter);

        state.healthScores = applyHealthScores(payload.data, userFilter);

        state.loading = false;
      })
      .addCase(getCompanyAnalyticsThunk.pending, (state) => {
        state.loading = true;
      });
  },
});

export const selectMainCompanyChart = (state: RootState) =>
  state.mainCompanyChart;

export const { reset, setActivity } = mainCompanyChartSlice.actions;
export default mainCompanyChartSlice.reducer;
