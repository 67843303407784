import { Card } from "../../../../assets/components/display/Card";
import classes from "../../styles/dashboard.module.scss";
import { locals } from "../../../../servises/locals";
import { useGetUser } from "../../../../hooks/useGetUser";
import { useSelector } from "react-redux";
import { selectUsersAnalytics } from "../../../../servises/store/slice/userAnalyticsSlice";
import { getDate } from "../../../../utils";
import { Button, Tooltip } from "antd";
import { HelpCircleIcon } from "../../../../assets/images/auth/svg";

export const UserInformation = () => {
  const { healthScores } = useSelector(selectUsersAnalytics);

  const user = useGetUser().userState;

  return (
    <Card>
      <div className={classes.card}>
        <div className={classes.card__title}>
          {user && getDate(user.creationTime, 0)}
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.user_registration_date}
        </div>
        <div className={classes.card__title}>
          {(user && user.todayScore && user.todayScore.index) || "N/A"}
          <div className={classes.tooltip}>
            <Tooltip
              title={
                <p>
                  Current long-term indicator of attitude to health.{" "}
                  <a
                    style={{
                      color: "var(--white)",
                      textDecoration: "underline",
                    }}
                    href="https://avi-health.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
            >
              <Button>
                <HelpCircleIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.user_current_health_score}
        </div>
        <div className={classes.card__title}>
          {healthScores.avg_health_index
            ? `${healthScores.avg_health_index.toFixed(0)}%`
            : "N/A"}
          <div className={classes.tooltip}>
            <Tooltip
              title={
                <p>
                  Average daily indicator of attitude to health in the selected
                  time period.{" "}
                  <a
                    style={{
                      color: "var(--white)",
                      textDecoration: "underline",
                    }}
                    href="https://avi-health.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              }
            >
              <Button>
                <HelpCircleIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.avg_health_index}
        </div>
        <div className={classes.card__title}>
          {healthScores.cooper_apanasenko || "N/A"}
          <div className={classes.tooltip}>
            <Tooltip
              title={
                <p>
                  Results of physical fitness tests passed. Gradation from 1 to
                  5, where 1 is very bad, 2 is bad, 3 is normal, 4 is good, and
                  5 is excellent
                </p>
              }
            >
              <Button>
                <HelpCircleIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.user_cooper_apanasenko_test}
        </div>
        <div className={classes.card__title}>
          {user &&
            getDate(
              user.todayIndex.date.seconds,
              user.todayIndex.date.nanoseconds
            )}
        </div>
        <div className={classes.card__subtitle}>
          {locals.information.user_date_of_the_last_visit}
        </div>
      </div>
    </Card>
  );
};
