import { collection, getDocs, query, where } from "@firebase/firestore";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { object, string } from "yup";

import { AuthWrappedCardComponent } from "../components/AuthWrappedCardComponent";
import { BaseInput } from "../../../assets/components/input/BaseInput";
import { BasePrimaryButton } from "../../../assets/components/input/BaseButton";
import { FirebaseContext } from "../../../App";
import { LinkButton } from "../../../assets/components/input/LinkButton";
import classes from "../styles/form.module.scss";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

export const Login = () => {
  const navigate = useNavigate();
  const db = useContext(FirebaseContext);

  const [message, setMessage] = useState("");
  const [resendBtn, setResendBtn] = useState(false);
  const [authState, setAuthState] = useState<any>();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: object({
      email: string().email().required(),
      password: string().required(),
    }),
    onSubmit: async ({ email, password }) => {
      const auth = getAuth();

      setMessage("");
      try {
        setAuthState(auth);
        const value = await signInWithEmailAndPassword(auth, email, password);
        if (
          auth.currentUser &&
          !auth.currentUser?.emailVerified &&
          auth.currentUser.email !== "shiichev@gmail.com"
        ) {
          setMessage(
            "Email is not verified. Please check the link that we have sent you to your Email."
          );
          setResendBtn(true);
          return;
        }
        localStorage.setItem("user_uid", value.user.uid);
        const company = query(
          collection(db, "/companies"),
          where("admin_user_uid", "==", value.user.uid)
        );
        const querySnapshot = await getDocs(company);
        let companyId = "";
        querySnapshot.forEach((item) => {
          companyId = item.id;
        });

        if (!companyId) {
          setMessage("There is no company registred for this user");
          return;
        }
        localStorage.setItem("company_ref_id", companyId);

        navigate("/");
      } catch (error: any) {
        if (error.message === "Firebase: Error (auth/user-not-found).")
          formik.setErrors({ email: "Email not found" });
        if (error.message === "Firebase: Error (auth/wrong-password).")
          formik.setErrors({ password: "Invalid Password" });
      }
    },
  });

  const resendHandler = async () => {
    const res = await sendEmailVerification(authState.currentUser!);
  };
  return (
    <AuthWrappedCardComponent title="Log In">
      <form onSubmit={formik.handleSubmit} className={classes.children_form}>
        <BaseInput
          name="email"
          disabled={false}
          label="E-mail"
          type="text"
          valid={!(formik.errors.email && formik.touched.email)}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <BaseInput
          name="password"
          disabled={false}
          label="Password"
          type="password"
          valid={!(formik.errors.password && formik.touched.password)}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {message && (
          <div className={classes.errorContainer}>
            <p className={classes.errorMessage}>{message}</p>
            {resendBtn && <Button onClick={resendHandler}>Resend email</Button>}
          </div>
        )}
        <div className={classes.forgot}>
          <a className={classes.forgotLink} href="/reset">
            Forgot password?
          </a>
        </div>

        <BasePrimaryButton> Log In </BasePrimaryButton>
        <LinkButton name="Sign Up" href="/auth" />
      </form>
    </AuthWrappedCardComponent>
  );
};
