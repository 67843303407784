export const locals = {
  auth: {
    passwordMissmatch: "Passwords do not match",
  },
  title: {
    nutrition: "Nutrition",
    activity: "Activity",
    mood: "Mood",
    dashboard: "Dashboard",
    invitations: "Invitations",
    top_health_score: "Top Health Score",
    choose_tarif_plan: "Choose your tariff plan",
    settings: "Settings",
    history: "History",
    inbox: "Inbox",
    sended_requests: "Sent",
    signout: "Are you sure you want log out?",
  },
  signout: {
    no: "No",
    yes: "Yes",
  },
  activity: {
    energy: "Energy",
    steps: "Steps",
    stand_min: "Stand Min",
    sleep: "Sleep",
  },
  mood: {
    general: "General",
    feeling: "Feeling",
    ext_factors: "Ext factors",
    family: "Family",
    work: "Work",
    smile1: "Very bad",
    smile2: "Bad",
    smile3: "Normal",
    smile4: "Good",
    smile5: "Excellent",
  },
  nutrition: {
    calories: "Calories",
    protein: "Protein",
    fats: "Fats",
    carbo: "Carbo",
    water: "Water",
  },
  filter: {
    user: {
      user: "User",
      all: "All",
      male: "Men",
      female: "Women",
    },
    age: "Age",
  },

  pay_board: {
    condition: {
      user_fee: "User fee per month 0,99$",
      analytic_dashboard: "Analytic dashborad",
      all_data_in_excel: "All data in Excel file",
      customization: "Customization",
      individual_tech: "Individual tech sup",
      personal_server: "Personal server",
      premium_account: "Premium account for your clients per month/ year 0$",
      per_month: "per month",
      per_year: "per year",
    },
  },

  information: {
    total_users: "Total users",
    men: "Men",
    women: "Women",
    avg_health_score: "Avg Health Score",
    avg_health_index: "Avg Health Index",
    user_registration_date: "Registration date",
    user_current_health_score: "Current Health Score",
    user_cooper_apanasenko_test: "Cooper/ Apanasenko test ",
    user_date_of_the_last_visit: "Date of the last visit ",
  },
};

export const NO_DATA = "No available data";
